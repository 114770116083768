<div fxLayout="row">
  <div id="footer-layout" fxLayout="row wrap" fxLayoutAlign="space-between center" class="navFooter">
    <div fxFlex="0 0 0 0">
      <div fxLayout="row">
        <div class="customized-icon">
          <span>?</span>
        </div>
        <a id="help" target="_blank" [href]="Messages.HELP_REF">
          <span>Help</span>
        </a>
      </div>
      <div fxLayout="row">
        <span class="contact-us-icon">&#64;</span>
        <div>
          <a id="contactUs" [href]="contactUsLink">
            <span class="contact-us-span">Contact Us</span>
          </a>
        </div>
      </div>
      <div fxLayout="row">
        <div class="customized-icon">
          <span>S</span>
        </div>
        <a iid="help" routerLinkActive="active" style="cursor:pointer" (click)="onSignOut()" tabindex="0" (keydown.enter)="onSignOut()">
          <span>Sign Out</span>
        </a>
      </div>
    </div>
    <div class="legal-text">All rights reserved {{currentYear}}(c) Accenture</div>
    <div fxFlex="0 0 0 0" fxLayout="row" fxLayoutGap="20px" class="right-wrap">
      <img _ngcontent-jlu-c82="" class="footer-img" alt="logo" src="../../assets/images/ACC-log.svg" />
    </div>
  </div>
</div>