/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, delay, retry } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { DeliverableService } from '../deliverable.service';

@Injectable()
export class DeliverableAPIService implements DeliverableService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  getDeliverable(deliverableId: any): Observable<any> {
    this.commonService.log('GET:', `/deliverables/${deliverableId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/deliverables/${deliverableId}`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getTypeAheadData(queryParams): Observable<any> {
    this.commonService.log('GET:', '/search/suggest', 'OPTIONS:', this.commonService.httpOptions(queryParams));
    return this.http.get(`${environment.API_URL}/search/suggest`, this.commonService.httpOptions(queryParams))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getFilter(parameters: any, formData: any): Observable<any> {
    parameters.search = this.commonService.replaceSearchParam(parameters.search);
    this.commonService.log('POST:', '/search', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.post(`${environment.API_URL}/search`, { filter: formData }, this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getSearchResults(parameters: any, formData: any): Observable<any> {
    parameters.search = this.commonService.replaceSearchParam(parameters.search);
    this.commonService.log('POST:', '/search/list', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.post(`${environment.API_URL}/search/list`, { filter: formData }, this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getKeywords(searchText: string): Observable<any> {
    const param = { search: searchText.toLowerCase().trim() };
    this.commonService.log('GET:', '/search/keywords', 'OPTIONS:', this.commonService.httpOptions(param));
    return this.http.get(`${environment.API_URL}/search/keywords`, this.commonService.httpOptions(param))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getAllDeliverables(params = null): Observable<any> {
    this.commonService.log('GET:', '/deliverables', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get(`${environment.API_URL}/deliverables`, this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
}

