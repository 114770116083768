<div fxLayout="column">
  <div fxLayout="column" class="filter-wrap" [formGroup]="filterForm" [@slideInOut]>
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutGap="10px" class="filter-set">
        <div class="form-group">
          <input type="text" aria-label="organization" id="organization" placeholder="Organization" class="form-control"
            [matAutocomplete]="organization" />
          <mat-autocomplete id="organization_auto" #organization="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('organization', $event)">
            <mat-option id="organization_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('organization', null);let i=index" [value]="option">
              {{option}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="organization_list">
            <mat-chip id="organization_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('organization');let i=index"
              (removed)="onRemoveMultipleSelectValue('organization', option)">
              {{option}}
              <span id="organization_delete{{i}}" matChipRemove
                (click)="onRemoveFilter('organization', option)"><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" class="filter-set">
        <div class="form-group">
          <input type="text" id="group" placeholder="Group" class="form-control" [matAutocomplete]="group"
            aria-label="group" />
          <mat-autocomplete id="group_auto" #group="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('group', $event)">
            <mat-option id="group_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('group', null);let i=index" [value]="option">{{option}}
            </mat-option>
          </mat-autocomplete>
          <mat-chip-set id="group_list">
            <mat-chip id="group_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('group');let i=index"
              (removed)="onRemoveMultipleSelectValue('group', option)">
              {{option}}
              <span id="group_delete{{i}}" matChipRemove
                (click)="onRemoveFilter('group', option)"><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" class="filter-set">
        <div class="form-group">
          <input type="text" id="location" placeholder="Location" class="form-control" [matAutocomplete]="location"
            aria-label="location" />
          <mat-autocomplete id="location_auto" #location="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('location', $event)">
            <mat-option id="location_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('location', null);let i=index" [value]="option">{{option}}
            </mat-option>
          </mat-autocomplete>
          <mat-chip-set id="location_list">
            <mat-chip id="location_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('location');let i=index"
              (removed)="onRemoveMultipleSelectValue('location', option)">
              {{option}}
              <span id="location_delete{{i}}" matChipRemove
                (click)="onRemoveFilter('location', option)"><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>

      </div>
      <div fxLayout="row" fxLayoutGap="10px" class="filter-set">
        <div class="form-group">
          <input id="keyword" type="text" placeholder="Keyword" class="form-control" [matAutocomplete]="keyword"
            (keyup)="onChangeKeyWord($event)" aria-label="keyword" />
          <mat-progress-bar *ngIf="loadKeywords" mode="indeterminate" color="accent" aria-label="keyword"></mat-progress-bar>
          <mat-autocomplete id="keyword_auto" #keyword="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('keyword', $event, null)">
            <mat-option id="keyword_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('keyword', null, true);let i=index" [value]="option">
              {{option}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="keyword_list">
            <mat-chip id="keyword_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('keyword');let i=index"
              (removed)="onRemoveMultipleSelectValue('keyword', option, null)">
              {{option}}
              <span id="keyword_delete{{i}}" matChipRemove
                (click)="onRemoveFilter('keyword', option)"><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" class="filter-set">
        <div class="form-group">
          <input type="text" aria-label="industry" id="industry" placeholder="Industry" class="form-control"
            [matAutocomplete]="industry" />
          <mat-autocomplete id="industry_auto" #industry="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('industry', $event)">
            <mat-option id="industry_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('industry', null);let i=index" [value]="option">
              {{option}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="industry_list">
            <mat-chip id="industry_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('industry');let i=index"
              (removed)="onRemoveMultipleSelectValue('industry', option)">
              {{option}}
              <span id="industry_delete{{i}}" matChipRemove
                (click)="onRemoveFilter('industry', option)"><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <!-- <div fxLayout="row" fxLayoutGap="60px" class="filter-set">
        <button id="clearAllFilters" mat-button class="clear-btn" (click)="onClearAllFilter()">
          <span class="ic-close"></span>Clear All Filters</button>
        <button id="applyFilters" mat-button class="filter-btn" (click)="onFilter('applyFilters')">Apply Filters
        </button>
      </div> -->
    </div>
  </div>
  <div fxLayout="row" class="asset-filter-btn-wrap">
    <div fxLayout="row" fxLayoutGap="40px" class="filter-set">
      <div fxLayout="row" class="form-group">
        <button id="clearAllFilters" type="button" mat-stroked-button class="clear-btn" color="primary" (click)="onClearAllFilter()">
          <span class="ic-close"></span>Clear All Filters</button>
        <button id="applyFilters" type="button" mat-flat-button color="primary" (click)="onFilter('applyFilters')">Apply Filters</button>
      </div>
    </div>
  </div>
</div>