import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Animations } from '@app/app-animations';
import { Services } from '@app/services/services';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-select-assets-filter',
  outputs: ['onChange'],
  templateUrl: './select-assets-filter.component.html',
  styleUrls: ['./select-assets-filter.component.scss'],
  animations: [
    Animations.slideInOut
  ]
})
export class SelectAssetsFilterComponent implements OnInit {
  allDeliverables: any;
  loading: any = 0;
  loadKeywords: any = 0;
  loadIndustries: any = 0;
  industryList: any = [];
  filterIndustryList: any = [];
  keywordChanged: Subject<string> = new Subject<string>();
  filterForm: UntypedFormGroup;
  filterLabels: any = {
    group: 'Group',
    location: 'Location',
    keyword: 'Keyword',
    organization: 'Organization',
    industry: 'Industry'
  }
  options: any = {
    group: [],
    location: [],
    keyword: [],
    organization: [],
    industry: []
  };
  assetsFilter: any = {
    group: [],
    location: [],
    keyword: [],
    organization: [],
    industry: []
  };
  @Input('sectionKey') filterSectionKey: string;
  onChange: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private formBuilder: UntypedFormBuilder,
    public services: Services,
  ) { }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      group: [[]],
      location: [[]],
      keyword: [[]],
      organization: [[]],
      industry: [[]]
    });
    this.keywordChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.length >= 2) {
          this.options['keyword'] = [];
          this._getKeywords(filterValue);
        }
      });

    this.getAllDeliverables();
    this.onFilter('init');
  }

  getAllDeliverables(): void {
    this.loading++;
    this.services.deliverableService.getAllDeliverables()
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.allDeliverables = response.result;
          this.options['organization'] = this.allDeliverables.map((o) => o.organization_type).filter((e) => e != null);
          this.options['group'] = this.allDeliverables.map((o) => o.primary_group_name).filter((e) => e != null);
          this.options['location'] = this.allDeliverables.map((o) => o.primary_location_name).filter((e) => e != null);
          this.industryList = this.allDeliverables.map((item) => item.industries).filter((e) => e != '');
          this.options['industry'] = this._repeatArry(this.industryList);
        }
      });
  }

  _repeatArry(arry: any): any[] {
    arry.forEach((element) => {
      element.forEach((o) => {
        this.filterIndustryList.push(o);
      });

    });
    return [...new Set(this.filterIndustryList)];;
  }
  _getKeywords(searchText: any): void {
    this.loadKeywords++;
    this.services.deliverableService.getKeywords(searchText)
      .subscribe((response) => {
        this.loadKeywords--;
        if (response && response.result) {
          this.options['keyword'] = response.result;
        }
      });
  }
  onChangeKeyWord(event): void {
    this.options['keyword'] = [];
    this.keywordChanged.next(event.target.value);
  }

  getMultipleSelectOptions(field: string, key = 'name', onlyTypeHead = false): any[] {
    return this.services.commonService.getMultipleSelectOptions(this, field, key, onlyTypeHead);
  }
  getMultipleSelectValues(field: string): any {
    return this.services.deliverableFilterCommonService.getMultipleSelectValues(this, field, null);
  }

  onAddMultipleSelectValue(field: string, event: MatAutocompleteSelectedEvent, key = '_id'): void {
    this.services.deliverableFilterCommonService.onAddMultipleSelectValue(this, field, event, null, key);
  }

  onRemoveMultipleSelectValue(field: string, value: any, key = '_id'): void {
    this.services.deliverableFilterCommonService.onRemoveMultipleSelectValue(this, field, value, key);
  }
  onFilter(type) {
    const values = this.filterForm.getRawValue();
    for (const item in values) {
      if (values[item] && values[item].length) {
        values[item].forEach((value) => {
          let index = -1;
          index = this.services.commonService.objectIndexOf(this.assetsFilter[item], value, null);
          if (index < 0) {
            this.assetsFilter[item].push(value);
          }
        });
      }
    }
    //  this.filterForm.reset();
    this.onChange.emit({ type: type, data: this.assetsFilter });
  }

  onRemoveFilter(field, option) {
    const index = this.services.commonService.objectIndexOf(this.assetsFilter[field], option, null);
    if (index >= 0) {
      this.assetsFilter[field].splice(index, 1);
    }
    this.onFilter('removeFilter');
  }
  onClearAllFilter() {
    this.filterForm.reset();
    this.assetsFilter = {
      group: [],
      location: [],
      keyword: [],
      organization: [],
      industry: []
    };
    this.onFilter('clearAll');
  }

  hasFilter() {
    return this.services.deliverableFilterCommonService.hasFilter(this);
  }
}
