<table mat-table [dataSource]="tableData">
  <ng-container matColumnDef="{{header}}" *ngFor="let header of displayedColumns; let i=index">
    <ng-container *ngIf="header === 'checked'; else notSelect">
      <td *matCellDef="let element;let j=index">
        <mat-checkbox id="selectOne{{j}}" [(ngModel)]="element.checked" (change)="selectOne(element)" (keydown.enter)="element.checked = !element.checked; selectOne(element)" color="accent" tabindex="0">
          <span style="display: none;">wave</span></mat-checkbox>
      </td>
    </ng-container>
    <ng-template #notSelect>
      <td id="tableDatas{{j}}" *matCellDef="let element;let j=index">
        <div class="assets-list">
          <app-assets-list [deliverable]="element[header]" [isChecked]="element.checked" [isShowLink] ="isShowLink"></app-assets-list>
        </div>
      </td>
    </ng-template>
  </ng-container>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>