import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@app/services';
import { Services } from '@app/services/services';
import { NgSelectComponent } from '@ng-select/ng-select';
@Component({
  selector: 'app-accessinnovation-home',
  templateUrl: './accessinnovation-home.component.html',
  styleUrls: ['./accessinnovation-home.component.scss']
})
export class AccessinnovationHomeComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  searchText;
  review: any;
  loading: any = 0;
  results: Array<any> = [];
  totalRecords: any = 0;
  showFilter: any = false;
  filter;
  count: any;
  search: any = null;
  loadTypeAhead: any = 0;
  pageSizeOptions = [10, 20, 30, 40, 50];
  pagination: any = {
    page_number: 0,
    page_size: 10,
    sort: 'last_updated_date',
    search: null,
    sort_dir: 'desc'
  }
  constructor(
    public commonService: CommonService,
    private cd: ChangeDetectorRef,
    private router: Router,
    public services: Services
  ) { }

  ngOnInit(): void {
    this.commonService.showHeader.emit(true);
    const pagination = this.commonService.getSessionData('SEARCH_PAGINATION');
    if (pagination) {
      this.pagination.page_number = pagination.page_number;
      this.pagination.page_size = pagination.page_size;
      this.pagination.search = pagination.search;
      this.pagination.sort = pagination.sort;
      this.pagination.sort_dir = pagination.sort_dir;
      this.searchText = pagination.search;
    }
  }

  onPageChange($event): void {
    this.pagination.page_number = $event.pageIndex;
    this.pagination.page_size = $event.pageSize;
    this._getSearchResult();
  }

  onOpenFilter() {
    this.showFilter = !this.showFilter;
  }

  /**
   * sort field
   * @param sort 
   */
  onSort(sort) {
    if (sort != '') {
      if (this.pagination.sort === sort) {
        this.pagination.sort_dir = this.pagination.sort_dir === 'asc' ? 'desc' : 'asc';
      } else {
        this.pagination.sort_dir = 'asc';
      }
    }
    else {
      this.pagination.sort_dir = '';
    }
    this.pagination.sort = sort;
    this.pagination.page_number = 0;
    this._getSearchResult();
  }

  onFilter($event) {
    if ($event.type == 'applyFilters') {
      this.showFilter = false;
    }
    this.filter = $event.data;
    if ($event.type != 'init') {
      this.pagination.page_number = 0;
    }
    this._getSearchResult();
  }
  
  /**
   * suggestion
   * @param event 
   */
  getTypeAhead(event) {
    const typeAheadValue = event.target.value;
    this.searchText = typeAheadValue;
    if (typeAheadValue.length > 1) {
      const reqArguments = {
        searchText: typeAheadValue
      };
      setTimeout(() => {
        this.loadTypeAhead++;
        this.cd.detectChanges();
      });
      this.services.deliverableService.getTypeAheadData(reqArguments).subscribe((typedata) => {
        if (typedata && typedata.suggestions) {
          this.review = typedata.suggestions;
        }
        setTimeout(() => {
          this.loadTypeAhead--;
          this.cd.detectChanges();
        });
      });
    }
    else {
      this.review = null;
    }
    if (typeAheadValue.length == 0) {
      this.ngSelectComponent.handleClearClick();
    }
  }
  /**
   * search field
   * @param $event 
   */
  onTypeAheadSearchSubmit($event = null) {
    if ($event != undefined) {
      this.searchText = $event.target.value;
    }
    this.pagination.search = this.searchText;
    this.pagination.page_number = 0;
    this._getSearchResult();
  }
  _getSearchResult(): void {
    setTimeout(() => {
      this.loadTypeAhead++;
      this.cd.detectChanges();
    });
    this.loading++;
    const filter = this.services.deliverableCommonService.getFilterData(this.filter);
    this.commonService.setSessionData('SEARCH_PAGINATION', this.pagination);
    this.services.deliverableService.getSearchResults(this.pagination, filter)
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.count = response.total;
          this.results = response.result;
          this.totalRecords = response.total;
        }
        setTimeout(() => {
          this.loadTypeAhead--;
          this.cd.detectChanges();
        });
      }, (error1) => {
        this.loading--;
        this.commonService.handleError(error1);
      });
  }

}
