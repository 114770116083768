import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Messages, Role, User } from '@app/models';
import { SelectAssetsPopupComponent } from '@app/select-assets-popup/select-assets-popup.component';
import { CommonService } from '@app/services';
import { Services } from '@app/services/services';
import { Observable, Observer, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CatalogueAPIService } from '../../app/services/api/catalogue-api.service';
@Component({
  selector: 'app-catalogue-edit',
  templateUrl: './catalogue-edit.component.html',
  styleUrls: ['./catalogue-edit.component.scss']
})
export class CatalogueEditComponent implements OnInit {
  deliverableImages: any = {};
  routerWatcher: any;
  catalogueForm: UntypedFormGroup;
  loginUser: User = null;
  catalogueName: any = '';
  publishSubmitted: any = false;
  saveSubmitted: any = false;
  shortDesc: any = '';
  loadKeywords: any = 0;
  longDesc: any = '';
  loading: any = 0;
  imgURL: any = null;
  maxFileSize: any = false;
  canEdite: any = false;
  file: any = null;
  loadPc: any = 0;
  loadAc: any = 0;
  isNew: any = true;
  catalog: any = null;
  catalogId: any = null;
  relAssChange: any = false;
  keywordChanged: Subject<string> = new Subject<string>();
  pcChanged: Subject<string> = new Subject<string>();
  acChanged: Subject<string> = new Subject<string>();
  options: any = {
    primary_curator: [],
    additional_curators: [],
    relevant_assets: [],
    industries: [],
    keywords: [],
    catalogue_org: [],
  }
  @ViewChild('validForm') validForm: ElementRef;
  isForList: boolean = true;
  uploadThumbnailSize: any = {
    width: 430,
    height: 265
  }
  relevantAssetsThumbnailSize: any = {
    width: 80,
    height: 64
  }
  currentUrl = '';
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public location: Location,
    public services: Services,
    public commonService: CommonService,
    public catalogueAPIService: CatalogueAPIService,
    public dialog: MatDialog
  ) {
    this.loginUser = this.services.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
    const righs = this.commonService.hasAnyRole([Role.GROUP_LEAD, Role.WORKSTREAM_LEAD, Role.GROUP_LOCATION_LEAD, Role.LOCATION_LEAD])
    this.services.commonService.removeSessionData('GET_SELECTED_ASSETS');
    this.services.deliverableCommonService.isNeedToRefresh.subscribe((isNeedToRefresh) => {
      if (isNeedToRefresh) {
        this.options.relevant_assets = this.services.commonService.getSessionData('GET_SELECTED_ASSETS');
      }
    });

    this.services.catalogueEditCommonService.getIndustries(this);
    this.services.catalogueEditCommonService.getOrganization(this);
    this.commonService.showHeader.emit(true);
    this.routerWatcher = this.route.params.subscribe((params) => {
      this.isNew = true;
      this.catalogId = null;

      if (params.catalogue_id !== 'new') {
        this.isNew = false;
        this.catalogId = params.catalogue_id;
        this.loading++;
        this.getCatalogs();
      } else {
        if (righs) {
          this.services.catalogueEditCommonService.createForm(this);
        }
      }

    });

    this.pcChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.trim().length >= 3) {
          this.options['primary_curator'] = [];
          this.services.catalogueEditCommonService.pcChanged(this, filterValue);
        }
      });
    this.keywordChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.trim().length >= 3) {
          this.options['keywords'] = [];
          this.services.catalogueEditCommonService.getKeywords(this, filterValue);
        }
      });
    this.acChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.trim().length >= 3) {
          this.options['additional_curators'] = [];
          this.services.catalogueEditCommonService.acChanged(this, filterValue);
        }
      });
  }

  getCatalogs() {
    this.catalogueAPIService.getMyCatalogue(this.catalogId)
      .subscribe((response) => {
        this.loading--;
        if (response && response.catalogue) {
          this.catalog = response.catalogue;
          this.catalog = { ...response.catalogue, ...response.metadata };
          this.catalogueName = this.catalog.name;
          this.shortDesc = this.catalog.short_description;
          this.longDesc = this.catalog.long_description;
          const user = this.commonService.getUser();
          this.canEdite = this.catalog.additional_curators.some(item => item.eso_id == user.eso_id);
          if (this.catalog.primary_curator.eso_id == user.eso_id) {
            this.canEdite = true;
          }
          if (this.canEdite) {
            this.services.catalogueEditCommonService.createForm(this, this.catalog);
            if (this.catalog.image_link) {
              this.services.catalogueEditCommonService.getCatalogImage(this, this.catalogId, this.isForList, this.uploadThumbnailSize);
            }
            this.options.relevant_assets = this.catalog.relevant_assets;
            this.services.commonService.setSessionData('GET_SELECTED_ASSETS', this.options.relevant_assets);
          }
        }
      });
  }
  get Messages(): any { return Messages; }

  getMultipleSelectOptions(field: string, key = 'name', onlyTypeHead = false, withoutFilter = false): any[] {
    return this.services.catalogueEditCommonService.getMultipleSelectOptions(this, field, key, onlyTypeHead, withoutFilter);
  }

  onAddMultipleSelectValue(field: string, event: MatAutocompleteSelectedEvent, allowEnter = false, key = '_id'): void {
    this.services.catalogueEditCommonService.onAddMultipleSelectValue(this, field, event, allowEnter, key);
  }
  onChangeKeyWord(event): void {
    this.options['keywords'] = [];
    this.keywordChanged.next(event.target.value);
  }
  getMultipleSelectValues(field: string): any {
    return this.catalogueForm.get(field).value;
  }

  onRemoveMultipleSelectValue(field: string, value: any, key = '_id'): void {
    this.services.catalogueEditCommonService.onRemoveMultipleSelectValue(this, field, value, key);
  }

  onAddSelectValue(field: string, event: MatAutocompleteSelectedEvent): void {
    this.services.catalogueEditCommonService.onAddSelectValue(this, field, event);
  }

  onChangePC(event): void {
    this.options['primary_curator'] = [];
    if (event.target.value == '') {
      this.catalogueForm.get('primary_curator').setValue(null);
    }
    this.pcChanged.next(event.target.value);
  }

  onChangeAC(event): void {
    this.options['team_members'] = [];
    this.acChanged.next(event.target.value);
  }

  _afterCreateForm() {
    this.catalogueForm.get('name').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this.catalogueName = this.catalogueForm.get('name').value;
      });

    this.catalogueForm.get('short_description').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this.shortDesc = this.catalogueForm.get('short_description').value;
      });

    this.catalogueForm.get('long_description').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this.longDesc = this.catalogueForm.get('long_description').value;
      });

    this.catalogueForm.get('primary_curator').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe((primary_curator) => {
        if (!primary_curator || primary_curator.eso_id !== this.loginUser.eso_id) {
          let additionalCurators = [];
          additionalCurators = this.getMultipleSelectValues('additional_curators');
          if (!additionalCurators) {
            additionalCurators = [];
          }
          const index = this.services.commonService.objectIndexOf(additionalCurators, this.loginUser);
          if (index < 0) {
            additionalCurators.push(this.loginUser);
          }
          this.catalogueForm.get('additional_curators').setValue(additionalCurators);
        }
      });
  }

  getImagePreview(files: any): void {
    if (files.length === 0)
      return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const limit = 5 * 1024 * 1024;
    if (files[0].size > limit) {
      this.maxFileSize = true;
      this.file = null;
      return;
    } else {
      this.maxFileSize = false;
    }
    this.file = files[0];
    this.catalogueForm.markAsDirty();
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.imgURL = reader.result;
    };
  }

  _isValid(): boolean {
    if (!this.options.relevant_assets) {
      this.options.relevant_assets = [];
    }
    if (this.catalogueForm.valid && !this.maxFileSize && this.options.relevant_assets.length) {
      return true;
    }
    this.invalidFocus();
    return false;
  }
  _isValidSave(): boolean {
    if (this.catalogueForm.get('name').valid && this.catalogueForm.get('primary_curator').valid && !this.maxFileSize) {
      return true;
    }
    this.invalidFocus();
    return false;
  }

  invalidFocus() {
    this.services.catalogueEditCommonService.invalidFocus(this);
  }

  openPopup(): void {
    this.relAssChange = true;
    this.dialog.open(SelectAssetsPopupComponent, {
      width: '1090px',
      height: '520px',
      maxHeight: '90vh',
      data: null
    });
  }

  canDeactivate() {
    if (this.catalogueForm && this.catalogueForm.dirty || this.relAssChange) {
      return Observable.create((observer: Observer<boolean>) => {
        const dialogRef = this.services.commonService.openDirtyDialog();
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.services.commonService.removeSessionData('GET_SELECTED_ASSETS');
          } else {
            this.location.go(this.currentUrl);
          }
          observer.next(result);
          observer.complete();
        });
      });
    }
    return true;
  }

  getDeliverableImage(deliverable: any): string {
    if (deliverable && deliverable.image_link) {
      const deliverableId = deliverable._id;
      if (this.deliverableImages[deliverableId] && this.deliverableImages[deliverableId] != 'loading') {
        return this.deliverableImages[deliverableId];
      } else if (this.deliverableImages[deliverableId] != 'loading') {
        this.deliverableImages[deliverableId] = 'loading';
        this.services.masterService.getImage(deliverableId, 'deliverable', this.isForList, this.relevantAssetsThumbnailSize)
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.deliverableImages[deliverableId] = reader.result;
              };
            }
          });
      }
    }
    return this.services.deliverableCommonService.defaultDeliverableImage;
  }

  onRemove(relevant_asset) {
    this.relAssChange = true;
    this.options.relevant_assets = this.options.relevant_assets.filter((o) => o._id !== relevant_asset._id);
    this.services.commonService.setSessionData('GET_SELECTED_ASSETS', this.options.relevant_assets);
  }

  onCancel(): void {
    this.currentUrl = this.location.path();
    this.location.back();
  }

  _getFromData(isPublish) {
    return this.services.catalogueEditCommonService.getFromData(this, isPublish);
  }

  onSave(): void {
    this.saveSubmitted = true;
    this.publishSubmitted = false;
    this.handleSaving(false);
  }

  onPublish(): void {
    this.publishSubmitted = true;
    this.saveSubmitted = false;
    this.handleSaving(true);
  }

  uploadorreplaceimage() {
    const fileInput = document.getElementById('catalogue_image');
    if (fileInput) {
      fileInput.click();
    }
  }

  handleSaving(isPublish) {
    let validation = false;
    if (this.saveSubmitted && this._isValidSave() && !this.loading) {
      validation = true;
    } else if (this.publishSubmitted && this._isValid() && !this.loading) {
      validation = true;
    }
    if (validation) {
      this.loading++;
      this.relAssChange = false;
      if (this.isNew) {
        this.catalogueAPIService.save(this.services.commonService.removeNullObjects(this._getFromData(isPublish)), this.file)
          .subscribe((response) => {
            this.services.catalogueEditCommonService.afterSave(this, response, this.isNew, isPublish);
            this.services.catalogueEditCommonService.notNeedToRemainPagination.next(true);
          });
      } else {
        this.catalogueAPIService.editCatalogue(this.catalogId, this.services.commonService.removeNullObjects(this._getFromData(isPublish)), this.file)
          .subscribe((response) => {
            this.services.catalogueEditCommonService.afterSave(this, response, this.isNew, isPublish);
            this.services.catalogueEditCommonService.notNeedToRemainPagination.next(true);
          });
      }
    }
  }
}
