import { Injectable } from '@angular/core';
import { CommonService, AuthenticationService, DeliverableService, DeliverableCommonService, CatalogueEditCommonService, CatalogueFilterCommonService, DeliverableFilterCommonService, MasterService, UserService, RouterExtService, StoriesService } from '@app/services';
@Injectable()
export class Services {
  constructor(public commonService: CommonService,
    public authenticationService: AuthenticationService,
    public deliverableCommonService: DeliverableCommonService,
    public catalogueEditCommonService: CatalogueEditCommonService,
    public catalogueFilterCommonService: CatalogueFilterCommonService,
    public masterService: MasterService,
    public deliverableService: DeliverableService,
    public deliverableFilterCommonService: DeliverableFilterCommonService,
    public userService: UserService,
    public routerExtService: RouterExtService,
    public storiesService: StoriesService
  ) {
  }
}
