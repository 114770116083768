/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, retry, delay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { CommonService } from '../common.service';


@Injectable()
export class CatalogueAPIService {
  constructor(private http: HttpClient, private commonService: CommonService) { }

  getFilter(parameters: any, formData: any): Observable<any> {
    parameters.search = this.commonService.replaceSearchParam(parameters.search);
    this.commonService.log('POST:', '/catalogues/list', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.post(`${environment.API_URL}/catalogues/list`, { filter: formData }, this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getMyCatalogue(catalogueId: any): Observable<any> {
    this.commonService.log('GET:', `/catalogues/my_catalogue/${catalogueId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/catalogues/my_catalogue/${catalogueId}`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getIndustries(): Observable<any> {
    this.commonService.log('GET:', '/catalogues/industries', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/catalogues/industries`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getOrganization(): Observable<any> {
    this.commonService.log('GET:', '/catalogues/organization', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/catalogues/organization`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  setFormData(catalogue, file): FormData {
    const formData = new FormData();
    if (file) { formData.append('catalogueImage', file); }
    formData.append('catalogue', JSON.stringify(catalogue));
    return formData;
  }

  save(catalogue, file): Observable<any> {
    const formData = this.setFormData(catalogue, file);
    this.commonService.log('POST:', '/catalogues', 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return this.http.post(`${environment.API_URL}/catalogues`, formData, this.commonService.httpOptions(null, null))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  editCatalogue(catalogueId, catalogue, file): Observable<any> {
    const formData = this.setFormData(catalogue, file);
    return this.http.post(`${environment.API_URL}/catalogues/edit/${catalogueId}`, formData, this.commonService.httpOptions(null, null))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getTypeAheadData(queryParams): Observable<any> {
    this.commonService.log('GET:', '/catalogues/suggest', 'OPTIONS:', this.commonService.httpOptions(queryParams));
    return this.http.get(`${environment.API_URL}/catalogues/suggest`, this.commonService.httpOptions(queryParams))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getCataloguesKeywords(searchText: string, published = ''): Observable<any> {
    const param = { search: searchText.toLowerCase().trim(), published: published };
    this.commonService.log('GET:', '/catalogues/search/keywords', 'OPTIONS:', this.commonService.httpOptions(param));
    return this.http.get(`${environment.API_URL}/catalogues/search/keywords`, this.commonService.httpOptions(param))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
}