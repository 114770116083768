<div fxLayout="column">
    <div fxLayout="column" class="filter-wrap catalogue-filter" [formGroup]="filterForm" *ngIf="show" [@slideInOut]>
        <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="33" class="form-group">
                    <input type="text" aria-label="organization" id="organization" placeholder="Organization"
                        class="form-control" [matAutocomplete]="organization" />
                    <mat-autocomplete id="organization_auto" #organization="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('organization', $event)">
                        <mat-option id="organization_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('organization');let i=index"
                            [value]="option.name">
                            {{option.name}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="organization_list">
                        <mat-chip id="organization_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('organization');let i=index"
                            (removed)="onRemoveMultipleSelectValue('organization', option, null)">
                            {{option}}
                            <span id="organization_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
                <div fxFlex="33" class="form-group">
                    <input id="keyword" type="text" placeholder="Keyword" class="form-control"
                        [matAutocomplete]="keyword" (keyup)="onChangeKeyWord($event)" aria-label="keyword" />
                    <mat-progress-bar *ngIf="loadKeywords" mode="indeterminate" color="accent" aria-label="keyword"></mat-progress-bar>
                    <mat-autocomplete id="keyword_auto" #keyword="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('keyword', $event, null)">
                        <mat-option id="keyword_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('keyword', null, true);let i=index"
                            [value]="option">
                            {{option}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="keyword_list">
                        <mat-chip id="keyword_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('keyword');let i=index"
                            (removed)="onRemoveMultipleSelectValue('keyword', option, null)">
                            {{option}}
                            <span id="keyword_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
                <div fxFlex="33" class="form-group">
                    <input type="text" aria-label="industry" id="industry" placeholder="Industry" class="form-control"
                        [matAutocomplete]="industry" />
                    <mat-autocomplete id="industry_auto" #industry="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('industry', $event)">
                        <mat-option id="industry_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('industry');let i=index"
                            [value]="option.name">
                            {{option.name}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="industry_list">
                        <mat-chip id="industry_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('industry');let i=index"
                            (removed)="onRemoveMultipleSelectValue('industry', option, null)">
                            {{option}}
                            <span id="industry_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
                <div fxFlex="33" class="form-group"></div>
            </div>
            <div fxLayout="row" fxLayoutGap="10px">
                <button id="applyFilters" mat-flat-button color="primary" (click)="onFilter('applyFilters')">Apply Filters
                </button>
            </div>
        </div>
    </div>
    <div fxLayout="row wrap" fxLayoutGap="13px" class="filter-values catalogue-filter" *ngIf="hasFilter()">
        <label id="lblAppliedFilters">Applied Filters:</label>
        <ng-container *ngFor="let item of filter | keyvalue;let i=index">
            <ng-container *ngIf="item.value && item.value.length">
                <div id="option{{i}}{{j}}" class="tag" *ngFor="let option of item.value;let j=index">
                    <span>{{filterLabels[item.key]}}:</span>&nbsp; {{ option}}
                    <a id="remove{{i}}{{j}}" class="remove_tag" (click)="onRemoveFilter(item.key, option)"><span
                            class="ic-close"></span></a>
                </div>
            </ng-container>
        </ng-container>
        <button id="clearAllFilters" mat-button matRipple [matRippleUnbounded]="false" [matRippleColor]="'rgba(235, 235, 235, .3)'" 
        [matRippleCentered]="false" class="clear-btn" (click)="onClearAllFilter()">Clear All
            Filters</button>
    </div>
</div>