<main fxFlex fxLayout="column">
    <app-header *ngIf="showHeader" fxShow="showHeader"></app-header>
    <mat-divider></mat-divider>
    <mat-sidenav-container fxFlex>
        <mat-sidenav *ngIf="showHeader" fxShow="showHeader" [(opened)]="opened" [mode]="mobileQuery.matches ? 'over' : 'side'">
        </mat-sidenav>
        <mat-sidenav-content #scrollContainer>
            <router-outlet (activate)="onActivate(scrollContainer)" *ngIf="!isIframe"></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
    <mat-divider></mat-divider>
    <app-footer *ngIf="showHeader" fxShow="showHeader"></app-footer>
</main>