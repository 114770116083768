import { Injectable } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Injectable()
export class CatalogueFilterCommonService {
    constructor(
    ) { }

    onAddMultipleSelectValue(self, field: string, event: MatAutocompleteSelectedEvent, metric = null, key = '_id'): void {
        let values = self.filterForm.get(field).value;
        if (!values) {
            values = [];
        }
        let input = document.getElementById(field);
        const index = self.services.commonService.objectIndexOf(values, event.option.value, null);
        if (index < 0) {
            values.push(event.option.value);
        }
        input['value'] = '';
        input.blur();
        self.filterForm.get(field).setValue(values);
    }

    onRemoveMultipleSelectValue(self, field: string, value: any, key = '_id'): void {
        const values = self.filterForm.get(field).value;
        const index = self.services.commonService.objectIndexOf(values, value, key);
        if (index >= 0) {
            values.splice(index, 1);
        }
        self.filterForm.get(field).setValue(values);
        self.filterForm.get(field).markAsDirty();
    }

    getMultipleSelectOptions(self, field: string, key = 'name', onlyTypeHead = false, withoutFilter = false): any[] {
        const input = document.getElementById(field);
        let filterValue = null;
        if (input) {
            filterValue = input['value'].toLowerCase();
        }
        if (onlyTypeHead && input && input['value'].trim() == '') {
            filterValue = null;
        }
        let options = [];
        options = self.options[field];
        if (withoutFilter) {
            return options;
        }
        if (filterValue) {
            if (key != null) {
                return options.filter((option) => {
                    return option[key] && (option[key].toLowerCase().includes(filterValue) || Object.keys(option).some((k) => option[k] && option[k].toString().toLowerCase().includes(filterValue)));
                }
                );
            }
            return options.filter((option) => {
                return option && option.toLowerCase().includes(filterValue);
            });
        } else if (onlyTypeHead) {
            return [];
        }
        return options;
    }

}