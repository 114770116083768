/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, delay, retry } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { StoriesService } from '../stories.service';

@Injectable()
export class StoriesAPIService implements StoriesService {
    constructor(private http: HttpClient, private commonService: CommonService) {
    }
    getStory(storyId: any): Observable<any> {
        this.commonService.log('GET:', `/stories/successStory/${storyId}`, 'OPTIONS:', this.commonService.httpOptions());
        return this.http.get(`${environment.API_URL}/stories/successStory/${storyId}`, this.commonService.httpOptions())
            .pipe(
                map(this.commonService.extractData),
                map((res: any) => {
                    return res;
                }),
                retry(environment.MAX_RETRY_COUNT),
                delay(environment.RETRY_INTERVAL),
                catchError((error: any) => {
                    return this.commonService.handleError(error);
                })
            );
    }



}

