import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Services } from '@app/services/services';

@Component({
  selector: 'app-story-list',
  templateUrl: './story-list.component.html',
  styleUrls: ['./story-list.component.scss']
})
export class StoryListComponent implements OnInit {
  @Input('story') story: any;
  onClick: EventEmitter<any> = new EventEmitter<any>();
  dataImages: any = {};
  isForList: boolean = true;
  imgURL;
  thumbnailSize: any = {
    width: 300,
    height: 200
  }
  constructor(
    public services: Services
  ) {
    this.imgURL = this.services.deliverableCommonService.defaultDeliverableImage;
  }

  ngOnInit(): void {
    if (this.story && this.story.pictures && this.story.pictures.length > 0) {
      const firstSSImage = this.adjustPicturesOrder();
      this.getDataImage(firstSSImage.image_url);
    } else {
      if (this.story && this.story.image_link1) {
        this.getDataImage(this.story.image_link1);
      }
    }
  }
  getDataImage(imageId): void {
    this.services.masterService.getImage(imageId, 'story', this.isForList, this.thumbnailSize)
      .subscribe((response) => {
        if (response && response.size > 0) {
          const reader = new FileReader();
          reader.readAsDataURL(response);
          reader.onload = () => {
            this.imgURL = reader.result;
          };
        }
      });
  }
  adjustPicturesOrder() {
    if (this.story.pictures[0].order) {
      this.story.pictures.sort(this.compare('order'));
    }
    return this.story.pictures[0];
  }

  compare(property) {
    return function (a, b) {
      const value1 = a[property];
      const value2 = b[property];
      return value1 - value2;
    };
  }
}
