import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingProviders, Routing } from '@app/app-routing';
import { AppComponent } from '@app/app.component';
import { ErrorComponent } from '@app/error/error.component';
import { HeaderComponent } from '@app/header/header.component';
import { HomeComponent } from '@app/home/home.component';
import { LoginComponent } from '@app/login/login.component';
import {
  AuthenticationService,
  CatalogueEditCommonService,
  CatalogueFilterCommonService,
  CommonService,
  DeliverableCommonService,
  DeliverableFactory,
  DeliverableFilterCommonService,
  DeliverableService,
  MasterFactory,
  MasterService,
  MiddlewareService,
  RouterExtService,
  StoriesService,
  StoryFactory,
  UserFactory,
  UserService
} from '@app/services';
import { Services } from '@app/services/services';
import { environment } from '@environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';

import { ConfirmDialog } from '@app/dialog/confirm-dialog';

import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  MatRippleModule
} from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { AccessinnovationDetailViewComponent } from './accessinnovation-detail-view/accessinnovation-detail-view.component';
import { AccessinnovationHomeComponent } from './accessinnovation-home/accessinnovation-home.component';
import { AccessinnovationListViewComponent } from './accessinnovation-list-view/accessinnovation-list-view.component';
import { CatalogueEditComponent } from './catalogue-edit/catalogue-edit.component';
import { CatalogueListDetailComponent } from './catalogue-list-detail/catalogue-list-detail.component';
import { CatalogueListViewComponent } from './catalogue-list-view/catalogue-list-view.component';
import { FooterComponent } from './footer/footer.component';
import { LandingFilterComponent } from './landing-filter/landing-filter.component';
import { globalRippleConfig } from './models/rippleConfig';
import { MyCatalogueListViewComponent } from './my-catalogue-list-view/my-catalogue-list-view.component';
import { BypassSecurityTrustHtmlPipe } from './pipe/bypass-security-trust-html.pipe';
import { AssetsListComponent } from './select-assets-popup/assets-list/assets-list.component';
import { AssetsTableComponent } from './select-assets-popup/assets-table/assets-table.component';
import { SelectAssetsFilterComponent } from './select-assets-popup/select-assets-filter/select-assets-filter.component';
import { SelectAssetsPopupComponent } from './select-assets-popup/select-assets-popup.component';
import { CatalogueAPIService } from './services/api/catalogue-api.service';
import { AuthGuard } from './shared/guards/auth.guard';
import { CanDeactivateGuard } from './shared/guards/can-deactivate.guard';
import { StoryViewComponent } from './story-detail-view/story-view.component';
import { StoryListComponent } from './story-list/story-list.component';
import { TileFilterComponent } from './tile-filter/tile-filter.component';
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

function loggerCallback(logLevel: LogLevel, message: string) {
  console.log('MSAL Angular: ', message);
}
@NgModule({ declarations: [
        AppComponent,
        HeaderComponent,
        LoginComponent,
        HomeComponent,
        ErrorComponent,
        ConfirmDialog,
        AccessinnovationHomeComponent,
        FooterComponent,
        AccessinnovationListViewComponent,
        LandingFilterComponent,
        AccessinnovationDetailViewComponent,
        CatalogueListViewComponent,
        StoryViewComponent,
        CatalogueListDetailComponent,
        SelectAssetsPopupComponent,
        SelectAssetsFilterComponent,
        AssetsListComponent,
        AssetsTableComponent,
        CatalogueEditComponent,
        MyCatalogueListViewComponent,
        BypassSecurityTrustHtmlPipe,
        TileFilterComponent,
        StoryListComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        Routing,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        NgSelectModule,
        MatButtonModule,
        MatSidenavModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatTabsModule,
        MatSelectModule,
        MatMenuModule,
        MatDividerModule,
        MatChipsModule,
        MatDialogModule,
        MatToolbarModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatProgressBarModule,
        MatTableModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSortModule,
        DragDropModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: environment.clientId,
                authority: environment.authority,
                redirectUri: environment.redirectUri
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: isIE // set to true for IE 11
            },
            system: {
                loggerOptions: {
                    loggerCallback: loggerCallback,
                    piiLoggingEnabled: false
                }
            }
        }), {
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: ['user.read', 'openid', 'profile']
            }
        }, {
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map([
                ['https://graph.microsoft.com', ['user.read.all']]
            ])
        })], providers: [
        AppRoutingProviders,
        MiddlewareService,
        CommonService,
        AuthenticationService,
        DeliverableCommonService,
        CatalogueEditCommonService,
        CatalogueFilterCommonService,
        DeliverableFilterCommonService,
        AuthGuard,
        CanDeactivateGuard,
        RouterExtService,
        Services,
        CatalogueAPIService,
        {
            provide: UserService,
            useFactory: UserFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: MasterService,
            useFactory: MasterFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: DeliverableService,
            useFactory: DeliverableFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: StoriesService,
            useFactory: StoryFactory,
            deps: [HttpClient, CommonService]
        },
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        MsalGuard,
        MsalBroadcastService,
        MsalService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
