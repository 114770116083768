<div fxLayout="column" class="list-view-container" *ngIf="story">
  <div fxLayout="row" class="top-line">
    <div fxFlex="35" class="img-container">
      <a [routerLink]="['/search', 'story', 'view', story._id]"  tabindex="0">
        <div id="imagePreview" class="image-preview">
          <div [style.background-image]="'url(' + imgURL + ')'" class="image-preview-inner" role="img" aria-label="Image Preview">
          </div>
        </div>
      </a>
    </div>
    <div fxFlex="65" class="right-region">
      <a [routerLink]="['/search', 'story', 'view', story._id]">
        <div id="name" class="name">{{story.name}}</div>
      </a>
      <div id="subtitle" class="short-description">{{story.subtitle}}</div>
    </div>
  </div>
  <div class="bottom-line">
    <div fxLayout="row" fxLayoutGap="10px">
      <div fxLayout="row">
        <label id="lblOwner" name="lblOwner">Owner:</label>
        <span id="owner_value" name="owner_value">{{story.owner_displayname?story.owner_displayname:'N/A'}}</span>
      </div>
      <div fxLayout="row">
        <label id="lblOrganization" name="lblOrganization">Organization:</label>
        <span id="organization_value" name="organization_value">{{story.organization_type?story.organization_type:'N/A'}}</span>
      </div>
      <div fxLayout="row">
        <label id="lblGroup" name="lblGroup">Group:</label>
        <span id="groupvalue" name="groupvalue">{{story.primary_group?story.primary_group.name:'N/A'}}</span>
      </div>
      <div fxLayout="row">
        <label id="lblLocation" name="lblLocation">Location:</label>
        <span id="location_value" name="location_value">{{story.primary_location?story.primary_location.name:'N/A'}}</span>
      </div>
    </div>
  </div>
</div>