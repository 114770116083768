<div fxFlex fxLayout="column" class="edit-catalogue" *ngIf="catalogueForm">
    <div fxFlex class="title">
        <h1 id="page_title">My Catalogues</h1>
        <p id="page_description">In this section you can find all your created catalogues and add new ones</p>
    </div>
    <div class="catalogue-table">
        <div fxLayout="column" fxLayoutGap="10px" *ngIf="isNew">
            <div id="addNewButton" class="add-new-button">New catalogue</div>
        </div>
        <div *ngIf="!isNew">
            <span id="editCatalogue" class="icon"><i class="fa fa-edit"></i>Edit catalogue</span>
        </div>
        <mat-divider></mat-divider>
        <form #validForm fxLayout="column" [formGroup]="catalogueForm">
            <div class="form-group" [ngClass]="{'error': (saveSubmitted || publishSubmitted) && catalogueForm.get('name').invalid }">
                <label id="lblCatalogueName">Catalogue name<span>*</span></label>
                <input id="catalogueName" type="text" class="form-control" formControlName="name" aria-label="delName" maxlength="300"/>
                <p id="catalogueNameLength" class="hint-message">{{catalogueName ? catalogueName.length :0}}/300</p>
            </div>
            <div class="form-group" [ngClass]="{'error': publishSubmitted && catalogueForm.get('short_description').invalid }">
                <label id="lblShortDescription">Short description<span>*</span></label>
                <textarea id="shortDescription" rows="3" class="form-control short-description-textarea" formControlName="short_description" maxlength="150" aria-label="shortDescription"></textarea>
                <p id="shortDescriptionLength" class="hint-message">{{shortDesc ? shortDesc.length :0}}/150</p>
            </div>
            <div class="form-group customized-chips">
                <label id="lblIndustry">Industry</label>
                <input id="industries" type="text" class="form-control" [matAutocomplete]="industries" [disabled]="catalogueForm.get('industries').disabled" aria-label="indusries"/>
                <mat-autocomplete id="industries_auto" #industries="matAutocomplete" (optionSelected)="onAddMultipleSelectValue('industries', $event)">
                    <mat-option id="industries_option{{i}}" *ngFor="let option of getMultipleSelectOptions('industries');let i=index" [value]="option">{{option.name}}</mat-option>
                </mat-autocomplete>
                <mat-chip-set id="industries_list">
                    <mat-chip id="industries_chip{{i}}" disableRipple="true" removable="true" selectable="false" *ngFor="let option of getMultipleSelectValues('industries');let i=index" (removed)="onRemoveMultipleSelectValue('industries', option)">
                        <span id="industries_delete{{i}}" matChipRemove *ngIf="!catalogueForm.get('industries').disabled"><i class="ic-close" tabindex="0" role="industry" [attr.aria-selected]="option.selected ? 'true' : 'false'" [attr.aria-label]="option.name"></i></span>
                        <span class="chip-text">{{option.name}}</span>
                    </mat-chip>
                </mat-chip-set>
            </div>
         
                <div class="form-group customized-chips" [ngClass]="{'error':publishSubmitted && !catalogueForm.get('keywords').value?.length }">
                    <label id="lblKeywords">Keywords<span>*</span></label>
                    <input id="keywords" type="text" class="form-control" [matAutocomplete]="keywords" [disabled]="catalogueForm.get('keywords').disabled" (keyup)="onChangeKeyWord($event)" (keyup.enter)="onAddMultipleSelectValue('keywords', $event, true)" [ngClass]="{'incomplete':publishSubmitted && !catalogueForm.get('keywords').value?.length}" aria-label="keywords" maxlength="300" />
                    <mat-progress-bar *ngIf="loadKeywords" mode="indeterminate" color="accent" aria-label="Keywords"></mat-progress-bar>
                    <mat-autocomplete id="keywords_auto" #keywords="matAutocomplete" (optionSelected)="onAddMultipleSelectValue('keywords', $event, true)">
                        <mat-option id="keywords_option{{i}}" *ngFor="let option of getMultipleSelectOptions('keywords', null, true);let i=index" [value]="option">{{option}}</mat-option>
                    </mat-autocomplete>
                    <span id="keywords_text_hint" *ngIf="!getMultipleSelectValues('keywords')?.length" class="hint" [matTooltip]="Messages.KEYWORD_HINT">{{Messages.KEYWORD_HINT}}</span>
                    <mat-chip-set id="keywords_list">
                        <mat-chip id="keywords_chip{{i}}" disableRipple="true" removable="true" selectable="false" *ngFor="let option of getMultipleSelectValues('keywords');let i=index" (removed)="onRemoveMultipleSelectValue('keywords', option, null)">
                            <span id="keywords_delete{{i}}" matChipRemove *ngIf="!catalogueForm.get('keywords').disabled"><i class="ic-close"  tabindex="0" role="keyword" [attr.aria-selected]="option.selected ? 'true' : 'false'" [attr.aria-label]="option" ></i></span>
                            <span class="chip-text">{{option}}</span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
            <div class="form-group" [ngClass]="{'error': (publishSubmitted && (imgURL == null || maxFileSize)) }">
                <label id="lblDeliverableImage">Catalogue image<span>*</span></label>
                <div class="image-container">
                    <input id="catalogue_image" type="file" #file class="form-control" (change)="getImagePreview(file.files)" [ngClass]="{'ng-invalid': publishSubmitted && maxFileSize}" />
                    <div class="image-preview">
                        <div *ngIf="imgURL" [style.background-image]="'url(' + imgURL + ')'" class="image-preview-inner"></div>
                        <label id="lblReplacePhoto" for="catalogue_image" class="label-btn" tabindex="0" *ngIf="!catalogueForm.get('image').disabled" (keydown.enter)="uploadorreplaceimage()"><i class="fa fa-upload" aria-hidden="true"></i>Upload or Replace image</label>
                    </div>
                </div>
            </div>
            <div class="form-group customized-chips">
                <label id="lblOrganization">Organization</label>
                <input id="catalogue_org" type="text" class="form-control" [matAutocomplete]="catalogue_org" [disabled]="catalogueForm.get('catalogue_org').disabled" aria-label="catalogue_org"/>
                <mat-autocomplete id="organization_auto" #catalogue_org="matAutocomplete" (optionSelected)="onAddMultipleSelectValue('catalogue_org', $event)">
                    <mat-option id="organization_option{{i}}" *ngFor="let option of getMultipleSelectOptions('catalogue_org');let i=index" [value]="option">{{option.name}}</mat-option>
                </mat-autocomplete>
                <mat-chip-set id="organization_list">
                    <mat-chip id="organization_chip{{i}}" disableRipple="true" removable="true" selectable="false" *ngFor="let option of getMultipleSelectValues('catalogue_org');let i=index" (removed)="onRemoveMultipleSelectValue('catalogue_org', option)">
                        <span id="organization_delete{{i}}" matChipRemove *ngIf="!catalogueForm.get('catalogue_org').disabled"><i class="ic-close" tabindex="0" role="organization" [attr.aria-selected]="option.selected ? 'true' : 'false'" [attr.aria-label]="option.name" ></i></span>
                        <span class="chip-text">{{option.name}}</span>
                    </mat-chip>
                </mat-chip-set>
            </div>
            <div class="form-group" [ngClass]="{'error': (saveSubmitted || publishSubmitted) && catalogueForm.get('primary_curator').invalid }">
                <label id="lblPC">Primary curator<span>*</span></label>
                <input id="primary_curator" type="text" class="form-control" [matAutocomplete]="primary_curator" [disabled]="catalogueForm.get('primary_curator').disabled" [value]="catalogueForm.get('primary_curator').value?catalogueForm.get('primary_curator').value.display_name:''" (keyup)="onChangePC($event)" aria-label="primary_curator"/>
                <mat-progress-bar *ngIf="loadPc" mode="indeterminate" color="accent" aria-label="primary_curator"></mat-progress-bar>
                <mat-autocomplete id="primary_curator_auto" #primary_curator="matAutocomplete" (optionSelected)="onAddSelectValue('primary_curator', $event)">
                    <mat-option id="primary_curator_option{{i}}" *ngFor="let option of getMultipleSelectOptions('primary_curator','email_id', true);let i=index" [value]="option">{{option.display_name}}</mat-option>
                </mat-autocomplete>
            </div>
            <div class="form-group customized-chips">
                <label id="lblAC">Additional curators</label>
                <input id="additional_curators" type="text" placeholder={{Messages.OWNER_HINT}} class="form-control" [matAutocomplete]="additional_curators" [disabled]="catalogueForm.get('additional_curators').disabled" (keyup)="onChangeAC($event)" aria-label="additional_curators"/>
                <mat-progress-bar *ngIf="loadAc" mode="indeterminate" color="accent" aria-label="additional_curators"></mat-progress-bar>
                <mat-autocomplete id="additional_curators_auto" #additional_curators="matAutocomplete" (optionSelected)="onAddMultipleSelectValue('additional_curators', $event, false, 'eso_id')">
                    <mat-option id="additional_curators_option{{i}}" *ngFor="let option of getMultipleSelectOptions('additional_curators','email_id', true);let i=index" [value]="option">{{option.display_name}}</mat-option>
                </mat-autocomplete>
                <mat-chip-set id="additional_curators_list">
                    <mat-chip id="additional_curators_chip{{i}}" disableRipple="true" removable="true" selectable="false" *ngFor="let option of getMultipleSelectValues('additional_curators');let i=index" (removed)="onRemoveMultipleSelectValue('additional_curators', option, 'eso_id')">
                        <span id="additional_curators_delete{{i}}" matChipRemove *ngIf="!catalogueForm.get('additional_curators').disabled"><i class="ic-close" tabindex="0" role="additional_curators" [attr.aria-selected]="option.selected ? 'true' : 'false'" [attr.aria-label]="option.display_name" ></i></span>
                        <span class="chip-text">{{option.display_name}}</span>
                    </mat-chip>
                </mat-chip-set>
            </div>
            <div class="form-group" [ngClass]="{'error': publishSubmitted && !catalogueForm.get('long_description').value }">
                <label id="lblLongDescription">Long description<span>*</span></label>
                <textarea id="longDescription" class="form-control long-description-textarea" formControlName="long_description" aria-label="longDescription"  maxlength="5000"></textarea>
                <p id="longDescriptionLength" class="hint-message">{{longDesc ? longDesc.length :0}}/5000</p>
            </div>
            <div class="form-group" [ngClass]="{'error': publishSubmitted && !options.relevant_assets.length }">
                <label id="lblLongRelevantAssets">Relevant assets<span>*</span></label>
                <div class="relevant-assets-container">
                    <ng-container *ngFor="let relevant_asset of options.relevant_assets;let i=index">
                        <div fxLayout="row" class="show-card">
                            <div id="btnRemove{{i}}" tabindex="0"  class="removeAssetButton" (keydown.enter)="onRemove(relevant_asset)" (click)="onRemove(relevant_asset)" [attr.aria-label]="'remove' + relevant_asset.name" ></div>
                            <div fxLayout="row" class="sub-show-card">
                                <img id="assetImage{{i}}" [src]="getDeliverableImage(relevant_asset)" alt="" class="asset-image">
                                <div id="assetName{{i}}" class="asset-name">{{relevant_asset.name}}</div>
                            </div>
                        </div>
                    </ng-container>
                    <div id="lblAddRelevantAssets" class="asset-btn" (click)="openPopup()" tabindex="0" (keydown.enter)="openPopup()"><div class="next">Next</div>Select assets</div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayoutGap="30px" class="btn-wrap">
                <button id="cancel" type="button" mat-stroked-button color="primary" (click)="onCancel()">Cancel</button>
                <button id="save" type="button" mat-stroked-button color="primary" (click)="onSave()" *ngIf="catalog?.workflow_status!='Published'">Save</button>
                <button id="publish" type="button" mat-flat-button color="primary" (click)="onPublish()" *ngIf="catalog?.workflow_status!='Published'">Publish</button>
                <button id="update" type="button" mat-flat-button color="primary" (click)="onPublish()" *ngIf="catalog?.workflow_status=='Published'">Update</button>
            </div>
        </form>
    </div>
</div>
