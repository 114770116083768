/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Messages } from '@app/models';
import { Subject } from 'rxjs';
import { CatalogueAPIService } from '../../app/services/api/catalogue-api.service';

@Injectable()
export class CatalogueEditCommonService {
  defaultDeliverableImage: any = 'assets/images/latest-asset.jpeg';
  notNeedToRemainPagination: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public catalogueAPIService: CatalogueAPIService,
  ) { }

  createForm(self, catalogue = null): void {
    self.catalogueForm = this.formBuilder.group({
      _id: [catalogue ? catalogue._id : null],
      name: [catalogue ? catalogue.name : null, [Validators.required, this.noWhitespaceValidator]],
      short_description: [catalogue ? catalogue.short_description : null, [Validators.required, this.noWhitespaceValidator]],
      industries: [catalogue ? catalogue.industries : []],
      keywords: [catalogue ? catalogue.keywords : [], Validators.required],
      image: [catalogue ? catalogue.image_link : null, Validators.required],
      catalogue_org: [catalogue ? catalogue.organization_type : []],
      primary_curator: [catalogue ? catalogue.primary_curator : self.loginUser, Validators.required],
      additional_curators: [catalogue ? catalogue.additional_curators : []],
      long_description: [catalogue ? catalogue.long_description : null, [Validators.required, this.noWhitespaceValidator]],
    }, {
      validator: this.extraValidator()
    });
    self._afterCreateForm();
  }

  extraValidator() {
    return (formGroup: UntypedFormGroup) => {

      // URL
      const urlCtrl = formGroup.controls['url'];
      // if (deliverableStatusCtrl.value && deliverableStatusCtrl.value.isComplete && deliverableTypeCtrl.value && !deliverableTypeCtrl.value.isPublish && deliverableTypeCtrl.value.link_required) {
      //     if (urlCtrl.value && !urlCtrl.value.length) {
      //         urlCtrl.setErrors({ required: true });
      //     } else {
      //         urlCtrl.setErrors(null);
      //     }
      // } else {
      //     urlCtrl.setErrors(null);
      // }
    };
  }


  getFromData(self, isPublish) {
    const formData = self.catalogueForm.getRawValue();
    if (formData.name) {
      formData.name = formData.name.trim();
    }
    if (formData.short_description) {
      formData.short_description = formData.short_description.trim();
    }
    if (formData.long_description) {
      formData.long_description = formData.long_description.trim();
    }
    formData.workflow_status = isPublish ? 'Published' : 'Saved';
    if (self.options.relevant_assets && self.options.relevant_assets.length > 0) {
      formData.relevant_assets = self.options.relevant_assets.map((o) => o._id);
    }
    return formData;
  }

  afterSave(self, response, isNew, isPublish) {
    self.loading--;
    if (response) {
      const successMessage = response.message == 'Catalogue saved' ? Messages.SAVE_SUCCESS : Messages.PUBLISH_SUCCESS;
      self.services.commonService.removeSessionData('GET_SELECTED_ASSETS');
      self.services.commonService.removeSessionData('select-assets-filter');
      self.services.commonService.openSnackBar('success', isNew ? successMessage : Messages.UPDATE_SUCCESS);
      this.formReset(self);
      self.router.navigate(['mycatalogues/list']);
    }
  }

  formReset(self) {
    const defaultCatalogue = {
      primary_curator: self.loginUser,
      additional_curators: [],
      relevant_assets: [],
      industries: [],
      catalogue_org: []
    };
    self.catalogueForm.reset(defaultCatalogue, { emitEvent: false });
  }


  pcChanged(self, searchText: any): void {
    self.loadPc++;
    self.services.userService.getUsers(searchText)
      .subscribe((response) => {
        self.loadPc--;
        if (response && response.result) {
          self.options.primary_curator = response.result;
        }
      });
  }

  acChanged(self, searchText: any): void {
    self.loadAc++;
    self.services.userService.getUsers(searchText)
      .subscribe((response) => {
        self.loadAc--;
        if (response && response.result) {
          self.options.additional_curators = response.result;
        }
      });
  }

  getKeywords(self, searchText: any): void {
    self.loadKeywords++;
    self.catalogueAPIService.getCataloguesKeywords(searchText)
      .subscribe((response) => {
        self.loadKeywords--;
        if (response && response.result) {
          self.options.keywords = response.result;
        }
      });
  }
  onAddMultipleSelectValue(self, field: string, event: MatAutocompleteSelectedEvent, allowEnter = false, key = '_id'): void {
    let values = self.catalogueForm.get(field).value;
    if (!values) {
      values = [];
    }
    const input = document.getElementById(field);
    if (allowEnter) {
      const value = input['value'];
      if (value.trim() != '') {
        const index = self.services.commonService.objectIndexOf(values, value, null);
        if (index < 0) {
          values.push(value);
        }
      }
    } else {
      const index = self.services.commonService.objectIndexOf(values, event.option.value, key);
      if (index < 0) {
        values.push(event.option.value);
      }
    }
    input['value'] = '';
    input.blur();
    self.catalogueForm.get(field).setValue(values);
    self.catalogueForm.get(field).markAsDirty();
  }

  onRemoveMultipleSelectValue(self, field: string, value: any, key = '_id'): void {
    const values = self.catalogueForm.get(field).value;
    const index = self.services.commonService.objectIndexOf(values, value, key);
    if (index >= 0) {
      values.splice(index, 1);
    }
    self.catalogueForm.get(field).setValue(values);
    self.catalogueForm.get(field).markAsDirty();
  }

  onAddSelectValue(self, field: string, event: MatAutocompleteSelectedEvent): void {
    const input = document.getElementById(field);
    input['value'] = event.option.viewValue;
    input.blur();
    self.catalogueForm.get(field).setValue(event.option.value);
    self.catalogueForm.get(field).markAsDirty();
  }


  getMultipleSelectOptions(self, field: string, key = 'name', onlyTypeHead = false, withoutFilter = false): any[] {
    const input = document.getElementById(field);
    let filterValue = null;
    if (input) {
      filterValue = input['value'].toLowerCase();
    }
    if (onlyTypeHead && input && input['value'].trim() == '') {
      filterValue = null;
    }
    let options = [];
    let filterResult = [];
    let keyArray: any;
    if (filterValue) {
      filterValue = filterValue.trim();
    }
    if (field == 'primary_curator') {
      const additionalCurators = self.catalogueForm.get('additional_curators').value;
      self.options['primary_curator'].forEach((user) => {
        const index = self.services.commonService.objectIndexOf(additionalCurators, user, 'eso_id');
        if (index < 0) {
          options.push(user);
        }
      });
    } else if (field == 'additional_curators') {
      const primary_curator = self.catalogueForm.get('primary_curator').value;
      if (primary_curator) {
        self.options['additional_curators'].forEach((item) => {
          if (item.eso_id !== primary_curator.eso_id) {
            options.push(item);
          }
        });
      } else {
        options = self.options['additional_curators'];
      }
    }
    else if (field == 'keywords') {
      options = self.options[field];
    }
    else {
      options = self.options[field];
    }
    if (withoutFilter) {
      return options;
    }
    if (filterValue) {
      if (key != null) {
        return options.filter((option) => {
          return option[key] && (option[key].toLowerCase().includes(filterValue) || Object.keys(option).some((k) => option[k] && option[k].toString().toLowerCase().includes(filterValue)));
        }
        );
      }
      return options.filter((option) => {
        return option && option.toLowerCase().includes(filterValue);
      });
    } else if (onlyTypeHead) {
      return [];
    }
    return options;
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }


  getIndustries(self, pageControl = 'match'): void {
    self.loading++;
    self.catalogueAPIService.getIndustries()
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          if (pageControl == 'tile_filter') {
            self.options.industry = response.result;
          } else {
            self.options.industries = response.result;
          }
        }
      });
  }

  getOrganization(self, pageControl = 'match'): void {
    self.loading++;
    self.catalogueAPIService.getOrganization()
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          if (pageControl == 'tile_filter') {
            self.options.organization = response.result;
          } else {
            self.options.catalogue_org = response.result;
          }
        }
      });
  }

  invalidFocus(self) {
    setTimeout(() => {
      const invalidMatControl = self.validForm.nativeElement.querySelector('.error .ng-invalid');
      if (invalidMatControl) {
        invalidMatControl.focus();
        return;
      }
      const invalidControl = self.validForm.nativeElement.querySelector('.error .form-control');
      if (invalidControl) {
        invalidControl.focus();
      }
    }, 0);
  }
  getCatalogImage(self, catalogId, isForList, thumbnailSize): void {
    self.services.masterService.getImage(catalogId, 'catalogue', isForList, thumbnailSize)
      .subscribe((response) => {
        if (response && response.size > 0) {
          const reader = new FileReader();
          reader.readAsDataURL(response);
          reader.onload = () => {
            self.imgURL = reader.result;
          };
        }
      });
  }
}