/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable id-blacklist */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfirmDialog } from '@app/dialog/confirm-dialog';
import { Messages, Role, SnackBarText } from '@app/models';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Observer, of } from 'rxjs';

@Injectable()
export class CommonService {
  showHeader: EventEmitter<any> = new EventEmitter();
  reportDeleted: EventEmitter<any> = new EventEmitter();
  reportCreated: EventEmitter<any> = new EventEmitter();
  unexpected_error = [400, 403, 405, 406, 408, 415, 429, 500];
  bad_network_error = [502, 503, 504];
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    public authService: MsalService) {
  }

  public allOfLocations: any[];
  public allOfGroups: any[];

  hasAnyRole(roles: any, user = null): boolean {
    if (!user) {
      user = JSON.parse(localStorage.getItem('currentUser'));
    }
    if (user) {
      if (roles) {
        if (roles.indexOf('*') !== -1) {
          return true;
        }
        let valid = false;
        if (user.labs_role) {
          for (const role of roles) {
            for (const userRoles of user.labs_role) {
              if (userRoles === role) {
                valid = true;
                break;
              }
            }
          }
        }
        return valid;
      }
      return true;
    }
    return false;
  }

  getUser(user = null) {
    if (!user) {
      user = JSON.parse(localStorage.getItem('currentUser'));
    }
    return user;

  }

  hasAnyLeadRole(user = null): boolean {
    const roles = [Role.GROUP_LEAD, Role.INITIATIVE_LEAD, Role.LOCATION_LEAD, Role.PROJECT_LEAD, Role.WORKSTREAM_LEAD, Role.GROUP_LOCATION_LEAD];
    return this.hasAnyRole(roles, user);
  }

  getIdToken() {
    return localStorage.getItem('msal.idtoken');
  }

  httpOptions(params = null, contentType = 'application/json'): any {
    let headers = new HttpHeaders();
    if (!environment.demo) {
      if (contentType) {
        headers = new HttpHeaders({
          'Content-Type': contentType,
          'Authorization': 'Bearer ' + this.getIdToken()
        });
      } else {
        headers = new HttpHeaders({
          'Authorization': 'Bearer ' + this.getIdToken()
        });
      }
    } else if (contentType) {
      headers = new HttpHeaders({
        'Content-Type': contentType
      });
    }

    const httpOptions = {
      headers: headers,
      params: new HttpParams(),
      withCredentials: true,
    };

    if (params) {
      for (const key in params) {
        if (params[key] != null && params[key] !== '') {
          if (params[key] instanceof Array) {
            params[key].forEach((item) => {
              httpOptions.params = httpOptions.params.append(`${key.toString()}`, item);
            });
          } else {
            httpOptions.params = httpOptions.params.append(key.toString(), params[key]);
          }
        }
      }
    }
    return httpOptions;
  }

  extractData(res: any = null): any {
    const body = res;
    return body || {};
  }

  handleError<T>(error: any, emit = true) {
    this.log(error);
    if (emit && error.status !== Messages.HTTP_STATUS_CODE_401 && error.status !== Messages.HTTP_STATUS_CODE_200) {
      let message = '';
      if (this.unexpected_error.includes(error.status) || error.status == 0) {
        message = Messages.UNEXPECTED_ERROR_DATA_MESSAGE;
      } else if (this.bad_network_error.includes(error.status)) {
        message = Messages.BAD_NETWORK_ERROR_MESSAGE;
      } else if (error && error.error) {
        if (error.error.error) {
          if (error.error.error.message) {
            message = error.error.error.message;
          } else {
            message = error.error.error;
          }
        }
      }
      if (message !== null && message !== undefined && message !== '') {
        this.openSnackBar(SnackBarText.ERROR, message, 5000);
      }
    }
    else if (error.status == Messages.HTTP_STATUS_CODE_401) {
      this.openSnackBar(SnackBarText.WARNING, Messages.SESSION_EXPIRED_DATA_MESSAGE, 5000);
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
    if (error.status === Messages.HTTP_STATUS_CODE_403) {
      this.router.navigate(['/home']);
    } else if (error.status === Messages.HTTP_STATUS_CODE_404) {
      this.router.navigate(['/error']);
    }
    return of(null as T);
  }

  log(...args): void {
    if (environment.log) {
      console.log(moment().format() + ':', ...args);
    }
  }

  removeSessionData(key = null): void {
    if (key) {
      sessionStorage.removeItem(key);
    } else {
      const tmpDict = {};
      tmpDict['GET_SELECTED_ASSETS'] = sessionStorage.getItem('GET_SELECTED_ASSETS');
      tmpDict['GET_DELIVERABLE_ORG'] = sessionStorage.getItem('GET_DELIVERABLE_ORG');
      tmpDict['GET_PROJECT_ORG'] = sessionStorage.getItem('GET_PROJECT_ORG');
      tmpDict['GET_QUICK_ORG'] = sessionStorage.getItem('GET_QUICK_ORG');
      sessionStorage.clear();
      sessionStorage.setItem('GET_SELECTED_ASSETS', tmpDict['GET_SELECTED_ASSETS']);
      sessionStorage.setItem('GET_DELIVERABLE_ORG', tmpDict['GET_DELIVERABLE_ORG']);
      sessionStorage.setItem('GET_PROJECT_ORG', tmpDict['GET_PROJECT_ORG']);
      sessionStorage.setItem('GET_QUICK_ORG', tmpDict['GET_QUICK_ORG']);
    }
  }

  getSessionData(key: string): any {
    return JSON.parse(sessionStorage.getItem(key));
  }

  setSessionData(key: string, JsonData: any): void {
    sessionStorage.setItem(key, JSON.stringify(JsonData));
  }

  // Local data

  getLocalData(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  setLocalData(key: string, JsonData: any): void {
    localStorage.setItem(key, JSON.stringify(JsonData));
  }

  removeLocalData(key = null): void {
    if (key) {
      localStorage.removeItem(key);
    } else {
      localStorage.clear();
    }
  }

  objectIndexOf(array: any[], value: any, key = '_id'): number {
    if (value) {
      if (key != null) {
        for (let i = 0, len = array.length; i < len; i++) {
          if (array[i][key] === value[key]) {
            return i;
          }
        }
      } else {
        for (let i = 0, len = array.length; i < len; i++) {
          if (array[i] === value) {
            return i;
          }
        }
      }
    }
    return -1;
  }

  getOnlyIds(array: any, key = '_id') {
    const res = [];
    array.forEach((item) => {
      res.push(item[key]);
    });
    return res;
  }

  removeNullObjects(object) {
    for (const key in object) {
      if (object[key] === null || object[key] === undefined) {
        delete object[key];
      } else if (object[key] instanceof Array && !object[key].length) {
        delete object[key];
      }
    }
    return object;
  }


  addHttp(url) {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = 'http://' + url;
    }
    return url;
  }

  openSnackBar(type, message, duration = 1500) {
    this.snackBar.open(message, 'X', {
      duration: duration,
      panelClass: type,
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }


  openDirtyDialog() {
    return this.dialog.open(ConfirmDialog, {
      width: '478px',
      data: {
        title: 'Confirm',
        message: Messages.DISCARD_MESSAGE,
        toShowCancel: true,
        buttonYesCaption: 'Yes',
        buttonNoCaption: 'No'
      }
    });
  }

  unSavedDialog(message) {
    return this.dialog.open(ConfirmDialog, {
      width: '478px',
      disableClose: true,
      data: {
        title: 'Confirm',
        message: message,
        toShowCancel: true,
        buttonYesCaption: 'Yes',
        buttonNoCaption: 'No'
      }
    });
  }

  openErrorDialog(message, errorList) {
    return Observable.create((observer: Observer<boolean>) => {
      const dialogRef = this.dialog.open(ConfirmDialog, {
        width: '478px',
        data: {
          title: 'Error',
          message: message,
          errorList: errorList,
          toShowCancel: false,
          buttonYesCaption: 'Okay',
        }
      });

      dialogRef.afterClosed().subscribe((result) => {
        observer.next(result);
        observer.complete();
      });
    });
  }
  compareValues(order = 'asc', key = 'name') {
    return function innerSort(a, b) {
      if (!a[key] || !b[key]) {
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }
  getSelectedValue(idValue, idsList) {
    const isPresent = idsList.some(function (el) { return el._id === idValue; });
    if (isPresent) {
      return idValue;
    }
    return null;
  }
  // eslint-disable-next-line complexity
  getMultipleSelectOptions(self, field: string, key = 'name', onlyTypeHead = false): any[] {
    let input = document.getElementById(field);
    let filterValue = null;
    if (input) {
      filterValue = input['value'].toLowerCase();
    }
    if (onlyTypeHead && input && input['value'].trim() == '') {
      filterValue = null;
    }
    let options = [];
    if (field == 'group') {
      options = this.filterOptionByGroup(self, options, field);
    } else if (field == 'location') {
      options = this.filterOptionByLocation(self, options, field);
    } else if (field == 'organization') {
      options = this.filterOptionByOrg(self, options, field);
    } else {
      options = self.options[field];
    }

    // uniq option items
    if (options && options.length > 0) {
      options = _.uniq(options);
    }
    if (filterValue) {
      if (key != null) {
        return options.filter((option) => option[key].toLowerCase().includes(filterValue));
      }
      return options.filter((option) => option.toLowerCase().includes(filterValue));
    } else if (onlyTypeHead) {
      return [];
    }

    return options;
  }
  filterOptionByLocation(self: any, options: any[], field: string) {
    let organizations = [];
    if (self.filterForm.get('organization')) {
      organizations = self.filterForm.get('organization').value;
    }
    let groups = [];
    if (self.filterForm.get('group')) {
      groups = self.filterForm.get('group').value;
    }
    if (organizations && organizations.length > 0) {
      organizations.forEach((org) => {
        self.options['location'].forEach((location) => {
          const selectedOrg = { name: 'organization_type', value: org };
          const currentField = { name: 'primary_location_name', value: location };
          if (groups && groups.length > 0) {
            groups.forEach((group) => {
              this.commomFilterOption(self, selectedOrg, { name: 'primary_group_name', value: group }, currentField, options);
            });
          } else {
            this.commomFilterOption(self, selectedOrg, null, currentField, options);
          }
        });
      });
    } else if (groups && groups.length > 0) {
      groups.forEach((group) => {
        self.options['location'].forEach((location) => {
          const selectedOrg = { name: 'primary_group_name', value: group };
          const currentField = { name: 'primary_location_name', value: location };
          this.commomFilterOption(self, selectedOrg, null, currentField, options);
        });
      });
    } else {
      options = self.options[field];
    }
    return options;
  }
  /**
   * 
   * @param self 
   * @param firstSelectedField: {name: selectedName, value: selectedValue}
   * @param secondSelectedField: {name: selectedName, value: selectedValue}
   * @param currentField: {name: selectedName, value: selectedValue} 
   * @param options 
   */
  commomFilterOption(self: any, firstSelectedField: any, secondSelectedField: any, currentField: any, options: any[]) {
    let selectedOptions = [];
    if (secondSelectedField != null) {
      selectedOptions = self.allDeliverables.filter((o) => o[firstSelectedField.name] == firstSelectedField.value && o[secondSelectedField.name] == secondSelectedField.value);
    } else {
      selectedOptions = self.allDeliverables.filter((o) => o[firstSelectedField.name] == firstSelectedField.value);
    }
    selectedOptions.forEach((o) => {
      if (o[currentField.name] === currentField.value) {
        if (this.objectIndexOf(options, currentField.value, null) < 0) {
          options.push(currentField.value);
        }
      }
    });
  }

  filterOptionByOrg(self: any, options: any[], field: string) {
    let groups = [];
    let locations = [];
    if (self.filterForm.get('group')) {
      groups = self.filterForm.get('group').value;
    }
    if (self.filterForm.get('location')) {
      locations = self.filterForm.get('location').value;
    }
    if (groups && groups.length > 0) {
      groups.forEach((group) => {
        self.options['organization'].forEach((org) => {
          const selectedOrg = { name: 'primary_group_name', value: group };
          const currentField = { name: 'organization_type', value: org };
          if (locations && locations.length > 0) {
            locations.forEach((location) => {
              this.commomFilterOption(self, selectedOrg, { name: 'primary_location_name', value: location }, currentField, options);
            });
          } else {
            this.commomFilterOption(self, selectedOrg, null, currentField, options);
          }
        });
      });
    } else if (locations && locations.length > 0) {
      locations.forEach((location) => {
        self.options['organization'].forEach((org) => {
          const selectedOrg = { name: 'primary_location_name', value: location };
          const currentField = { name: 'organization_type', value: org };
          this.commomFilterOption(self, selectedOrg, null, currentField, options);
        });
      });
    } else {
      options = self.options[field];
    }
    return options;
  }

  filterOptionByGroup(self: any, options: any[], field: string) {
    let organizations = [];
    if (self.filterForm.get('organization')) {
      organizations = self.filterForm.get('organization').value;
    }
    let locations = [];
    if (self.filterForm.get('location')) {
      locations = self.filterForm.get('location').value;
    }
    if (organizations && organizations.length > 0) {
      organizations.forEach((org) => {
        self.options['group'].forEach((group) => {
          const selectedOrg = { name: 'organization_type', value: org };
          const currentField = { name: 'primary_group_name', value: group };
          if (locations && locations.length > 0) {
            locations.forEach((location) => {
              this.commomFilterOption(self, selectedOrg, { name: 'primary_location_name', value: location }, currentField, options);
            });
          } else {
            this.commomFilterOption(self, selectedOrg, null, currentField, options);
          }
        });
      });
    } else if (locations && locations.length > 0) {
      locations.forEach((location) => {
        self.options['group'].forEach((group) => {
          const selectedOrg = { name: 'primary_location_name', value: location };
          const currentField = { name: 'primary_group_name', value: group };
          this.commomFilterOption(self, selectedOrg, null, currentField, options);
        });
      });
    } else {
      options = self.options[field];
    }
    return options;
  }

  replaceSearchParam(search: string) {
    if(search && (/^[\*]+$/).test(search)){
      search = search.replace(/\*/g,'')
    }
    return search;
  }

}
