/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

import { CommonService } from '../common.service';
import { MasterService } from '../master.service';

@Injectable()
export class MasterMockService implements MasterService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  getDeliverableTypes(params = null): Observable<any> {
    this.commonService.log('GET:', '/deliverabletypes', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get('/assets/json/demo/deliverabletypes.json', this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getProjects(params = null): Observable<any> {
    this.commonService.log('GET:', '/projects', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get('/assets/json/demo/projects.json', this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getInitiatives(params = null): Observable<any> {
    this.commonService.log('GET:', '/initiatives', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get('/assets/json/demo/initiatives.json', this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          res = { result: res };
          return res;
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getQuarters(): Observable<any> {
    this.commonService.log('GET:', '/quarter/list', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/quarters.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getGroups(params = null): Observable<any> {
    this.commonService.log('GET:', '/groups', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get('/assets/json/demo/groups.json', this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getFunctions(): Observable<any> {
    return this.http.get('/assets/json/demo/functions.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getIndustries(): Observable<any> {
    this.commonService.log('GET:', '/industries', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/industries.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getImage(id, type = 'deliverable'): Observable<any> {
    const param = { id: id, type: type };
    this.commonService.log('GET:', '/images', 'OPTIONS:', this.commonService.httpOptions(param));
    return of(null);
  }

  getProfileImage(id: string): Observable<any> {
    this.commonService.log('GET:', `https://graph.microsoft.com/v1.0/users/${id}/photo/$value`, 'OPTIONS:', this.commonService.httpOptions());
    return of(null);
  }
  getLocations(params = null): Observable<any> {
    this.commonService.log('GET:', '/locations', 'OPTIONS:', this.commonService.httpOptions(params));
    return of(null);
  }
  saveOrder(params): Observable<any> {
    return of(params);
  }
  getWorkstreams(): Observable<any> {
    return of(null);
  }
}

