/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { of, Observable } from 'rxjs';

export class DeliverableService {

  getDeliverable(deliverableId: any): Observable<any> {
    return of(deliverableId);
  }

  getTypeAheadData(queryParams): Observable<any> {
    return of(queryParams);
  }

  getFilter(parameters: any, formData: any): Observable<any> {
    return of(formData, parameters);
  }
  getSearchResults(parameters: any, formData: any): Observable<any> {
    return of(formData, parameters);
  }

  getKeywords(searchText: string): Observable<any> {
    return of(searchText);
  }
  getAllDeliverables(params = null): Observable<any> {
    return of(params);
  }

}

