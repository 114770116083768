import { EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { CommonService, UserService, AuthenticationService } from '@app/services';
import { environment } from '@environments/environment';
import { EventMessage } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  showHeader = false;
  mobileQuery: MediaQueryList;
  mobileQueryListener: () => void;
  opened = true;
  route: string;
  redirectURL: any = '/home';
  isIframe = false;
  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private media: MediaMatcher,
    private router: Router,
    private location: Location,
    public commonService: CommonService,
    private msalBroadcastService: MsalBroadcastService,
    public authService: MsalService,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
  }

  ngOnInit(): void {
    if (!environment.demo) {
      this.isIframe = window !== window.parent && !window.opener;

      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
          takeUntil(this._destroying$)
        )
        .subscribe((result) => {
          this.checkAccount()
          localStorage.setItem('msal.idtoken', result.payload['idToken']);
          sessionStorage.setItem('ddrumsdk.idtoken', result.payload['idToken']);
          this.checkLogin();
        });
    }

    this.router.events.subscribe((event) => {
      if (event && event['url'] && event['url'] != '/' && event['url'] != '/#' && event['url'] != '/error' && event['url'] != '/home' && !event['url'].includes('#code=')) {
        this.redirectURL = event['url'];
      }
      if (this.location.path() !== '') {
        this.route = this.location.path();
      } else {
        this.route = '';
      }

      if (this.mobileQuery.matches) {
        this.opened = false;
      } else {
        this.opened = true;
      }
    });

    if (this.mobileQuery.matches) {
      this.opened = false;
    } else {
      this.opened = true;
    }

    this.commonService.showHeader.subscribe((showHeader) => {
      setTimeout(() => this.showHeader = showHeader);
    });


    window.addEventListener('resize', () => {
      if (this.mobileQuery.matches) {
        this.opened = false;
      } else {
        this.opened = true;
      }
    });
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  onActivate(scrollContainer: any): void {
    scrollContainer.elementRef.nativeElement.scrollTop = 0;
  }

  checkAccount() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  async checkLogin(value = false) {
    if (this.loggedIn) {
      this.signUp(value);
    }
  }

  signUp(value) {
    this.userService.signUp().subscribe((response) => {
      if (response && response.result) {
        this.authenticationService.setCurrentUserValue(response.result);
        this.authenticationService.currentUserSubject.next(response.result);
        this.commonService.removeSessionData();
        this.router.navigate([this.redirectURL]);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
