import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CommonService, } from '@app/services';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.commonService.showHeader.emit(false);
    if (environment.demo) {
      this.router.navigate(['/login']);
    }
    this.router.navigate(['/home']);
  }
}
