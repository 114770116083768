<div fxLayout="column" fxLayoutGap="10px" class="deliverable-container" *ngIf="deliverable">
  <div fxLayout="row" fxLayoutGap="10px" class="top-btn-wrap">
    <div id="back" class="back" (click)="onBack()" (keydown.enter)="onBack()" tabindex="0">Back</div>
    <div id="share" class="share" (keydown.enter)="services.deliverableCommonService.sendRecommend(deliverable)" tabindex="0"(click)="services.deliverableCommonService.sendRecommend(deliverable)">Share</div>
  </div>
  <div fxLayout="column" fxLayoutGap="10px">
    <div id="name" class="name" aria-labelledby="deliverable">{{deliverable.name}}</div>
  </div>
  <div fxLayout="row" fxLayoutGap="10px">
    <div fxLayout="column" fxFlex="50" fxLayoutGap="10px">
      <div id="short_description">{{deliverable.short_description}}</div>
      <div id="long_description" *ngIf="deliverable.long_description" class="long-description"
        [innerHTML]="deliverable.long_description">
      </div>
      <a id="url{{i}}" [href]="services.commonService.addHttp(option)" target="_blank"
        *ngFor="let option of deliverable.url;let i=index">{{option}}</a>
    </div>
    <div fxLayout="column" fxFlex="50" fxLayoutGap="10px" class="right-column">
      <div id="imagePreview" class="image-preview">
        <div [style.background-image]="'url(' + getDeliverableImage(deliverable) + ')'" class="image-preview-inner"></div>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        <div id="title_owner" class="title-name">Owner</div>
        <div fxLayout="row" fxLayoutGap="10px">
          <div id="owner_displayname" class="view-bg">{{deliverable.owner_displayname}}</div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        <div id="title_organization" class="title-name">Organization</div>
        <div fxLayout="row" fxLayoutGap="10px">
          <div id="organization_type" class="view-bg">{{deliverable.organization_type}}</div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="10px" *ngIf="deliverable.industries?.length">
        <div id="title_industry" class="title-name">Industry</div>
        <div fxLayout="row wrap" fxLayoutGap="10px">
          <div id="industries{{i}}" class="view-bg data-set" *ngFor="let option of deliverable.industries;let i=index">
            {{option}}</div>
        </div>
      </div>
      <div fxLayout="column">
        <div fxLayout="column" fxLayoutGap="10px" *ngIf="allGroups.length">
          <div id="title_group" class="title-name">Group</div>
          <div fxLayout="row wrap" fxLayoutGap="10px">
            <div id="allGroups{{i}}" class="view-bg data-set" *ngFor="let option of allGroups;let i=index">
              {{option}}</div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="10px" *ngIf="allWorkstreams.length">
          <div id="title_workstream" class="title-name">Workstream</div>
          <div fxLayout="row wrap" fxLayoutGap="10px">
            <div id="allWorkstreams{{i}}" class="view-bg data-set" *ngFor="let option of allWorkstreams;let i=index">
              {{option}}</div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="10px" *ngIf="allLocations.length">
          <div id="title_location" class="title-name">Location</div>
          <div fxLayout="row wrap" fxLayoutGap="10px">
            <div id="allLocations{{i}}" class="view-bg data-set" *ngFor="let option of allLocations;let i=index">
              {{option}}</div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="10px" *ngIf="deliverable.keywords.length">
        <div id="title_keywords" class="title-name">Keywords</div>
        <div fxLayout="row wrap" fxLayoutGap="10px">
          <div id="keywords{{i}}" class="view-bg data-set" *ngFor="let option of deliverable.keywords;let i=index">
            {{option}}</div>
        </div>
      </div>
    </div>
  </div>
</div>