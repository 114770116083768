import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '@app/services';
import { Services } from '@app/services/services';
import { CatalogueAPIService } from '../../app/services/api/catalogue-api.service';
import { Role } from '@app/models';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-catalogue-list-view',
  templateUrl: './catalogue-list-view.component.html',
  styleUrls: ['./catalogue-list-view.component.scss']
})
export class CatalogueListViewComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  results: Array<any> = [];
  totalRecords: any = 0;
  loading: any = 0;
  loadTypeAhead: any = 0;
  searchText: any = null;
  review: any;
  filter: any;
  pageSizeOptions = [9, 18, 27, 36, 45];
  dataImages: any = {};
  pagination: any = {
    page_number: 0,
    page_size: 9,
    sort: 'last_updated_date',
    search: null,
    sort_dir: 'desc'
  }
  showFilter: any = false;
  imgURL = 'assets/images/latest-asset.jpeg';
  isForList: boolean = true;
  thumbnailSize: any = {
    width: 160,
    height: 160
  }
  constructor(
    public commonService: CommonService,
    public services: Services,
    public catalogueAPIService: CatalogueAPIService,
    private cd: ChangeDetectorRef,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.commonService.showHeader.emit(true);
    this.getNewCurrentUser();
    const pagination = this.commonService.getSessionData('CATALOGUE_TILE_PAGINATION');
    if (pagination) {
      this.pagination.page_number = pagination.page_number;
      this.pagination.page_size = pagination.page_size;
      this.pagination.search = pagination.search;
      this.pagination.sort = pagination.sort;
      this.pagination.sort_dir = pagination.sort_dir;
      this.searchText = pagination.search;
    }
  }
  get Role(): any { return Role; }
  getNewCurrentUser() {
    this.services.userService.signUp().subscribe((response) => {
      if (response && response.result) {
        this.services.authenticationService.setCurrentUserValue(response.result);
        this.services.authenticationService.currentUserSubject.next(response.result);
      }
    });
  }
  onPageChange($event): void {
    this.pagination.page_number = $event.pageIndex;
    this.pagination.page_size = $event.pageSize;
    this._getSearchResult();
  }

  _getSearchResult(): void {
    setTimeout(() => {
      this.loadTypeAhead++;
      this.cd.detectChanges();
    });
    this.loading++;
    const filter = this.services.deliverableCommonService.getFilterData(this.filter);
    filter['workflow_status'] = ['Published'];
    this.commonService.setSessionData('CATALOGUE_TILE_PAGINATION', this.pagination);
    this.catalogueAPIService.getFilter(this.pagination, filter)
      .subscribe((response) => {
        this.loading--;
        if (response && response.catalogues) {
          this.results = response.catalogues;
          this.totalRecords = response.total;
        }
        setTimeout(() => {
          this.loadTypeAhead--;
          this.cd.detectChanges();
        });
      }, (error1) => {
        this.loading--;
        this.commonService.handleError(error1);
      });
  }

  onSort(sort) {
    if (sort != '') {
      if (this.pagination.sort === sort) {
        this.pagination.sort_dir = this.pagination.sort_dir === 'asc' ? 'desc' : 'asc';
      } else {
        this.pagination.sort_dir = 'asc';
      }
    }
    else {
      this.pagination.sort_dir = '';
    }
    this.pagination.sort = sort;
    this.pagination.page_number = 0;
    this._getSearchResult();
  }

  onOpenFilter() {
    this.showFilter = !this.showFilter;
  }

  onFilter($event) {
    if ($event.type == 'applyFilters') {
      this.showFilter = false;
    }
    this.filter = $event.data;
    if ($event.type != 'init') {
      this.pagination.page_number = 0;
    }
    this._getSearchResult();
  }

  handleIndustriesData(data) {
    let allIndustries = [];
    data.forEach(element => {
      allIndustries.push(element.name);
    });
    return allIndustries.join(' , ');
  }

  createCatalogue(): void {
    this.redirectTo('/catalogues/new');
  }

  redirectTo(uri: string): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
  }
  getDataImage(data) {
    if (data && data.image_link) {
      const catalogueId = data._id;
      if (this.dataImages[catalogueId] && this.dataImages[catalogueId] != 'loading') {
        return this.dataImages[catalogueId];
      } else if (this.dataImages[catalogueId] != 'loading') {
        this.dataImages[catalogueId] = 'loading';
        this.services.masterService.getImage(catalogueId, 'catalogue', this.isForList, this.thumbnailSize)
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.dataImages[catalogueId] = reader.result;
              };
            }
          });
      }
    }
    return null;
  }
  getTypeAhead(event) {
    const typeAheadValue = event.target.value;
    this.searchText = typeAheadValue;
    if (typeAheadValue.length > 1) {
      const reqArguments = {
        searchText: typeAheadValue
      };
      setTimeout(() => {
        this.loadTypeAhead++;
        this.cd.detectChanges();
      });
      this.catalogueAPIService.getTypeAheadData(reqArguments).subscribe((typedata) => {
        if (typedata && typedata.suggestions) {
          this.review = typedata.suggestions;
        }
        setTimeout(() => {
          this.loadTypeAhead--;
          this.cd.detectChanges();
        });
      });
    }
    else {
      this.review = null;
    }
    if (typeAheadValue.length == 0) {
      this.ngSelectComponent.handleClearClick();
    }
  }
  onTypeAheadSearchSubmit($event = null) {
    if ($event != undefined) {
      this.searchText = $event.target.value;
    }
    this.pagination.search = this.searchText;
    this.pagination.page_number = 0;
    this._getSearchResult();
  }
}
