<div fxLayout="column" class="home-page">
  <div fxFlex class="title">
    <h1 id="page_title" aria-labelledby="page_title">Welcome to Access Innovation</h1>
    <p id="page_description" aria-labelledby="page_description">Are you ready to get inspired? It's great to have you here.</p>
  </div>
  <div fxLayout="row" fxLayoutGap="10px" class="home-container">
<div id="searchArea" class="search-wrap" role="search" aria-label="Search assets">
    <mat-progress-bar *ngIf="loadTypeAhead" mode="indeterminate" color="accent" aria-label="Loading assets..." ></mat-progress-bar>
    <ng-select id="addSearchKey" class="custom WavSelect WavOption" bindLabel="name" bindValue="name" groupBy="type"
     [(ngModel)]="searchText" placeholder="Search assets… " [markFirst]="false" [editableSearchTerm]="true" aria-label="Search assets" 
     [inputAttrs]='{"aria-label":"Search"}' [inputAttrs]='{"autocomplete":"off"}' [closeOnSelect]="true" (input)="getTypeAhead($event)" 
     (keydown.enter)="onTypeAheadSearchSubmit($event)" (change)="onTypeAheadSearchSubmit()" [items]="review" role="search">
    </ng-select>
</div>
    <button id="sortMenuIcon" mat-button color="primary" class="sort-btn" [matMenuTriggerFor]="sortMenu" aria-label="Sort menu">
      <div class="btn-inner">
        <span>
          <span id="s_sort">Sort : </span>
          <span id="s_select" class="sort-value" *ngIf="!pagination.sort">Select </span>
          <span id="s_mostRecent" class="sort-value" *ngIf="pagination.sort == 'last_updated_date'">Most Recent</span>
          <span id="s_alphabetical" class="sort-value" *ngIf="pagination.sort == 'name'">Alphabetical</span>
          <span id="s_sort_dir" *ngIf="pagination.sort_dir">({{pagination.sort_dir}})</span>
        </span>
        <span class="icon ic-dropdown"></span>
      </div>
    </button>
    <mat-menu #sortMenu="matMenu" class="live-menu sort-menu">
      <button id="clearSort" mat-menu-item (click)="onSort('')"
        *ngIf="pagination.sort == 'name' || pagination.sort == 'last_updated_date'">
        Clear Sort
      </button>
      <button id="mostRecent" mat-menu-item (click)="onSort('last_updated_date')">
        Most Recent
        <span class="icon ic-dropdown" *ngIf="pagination.sort == 'last_updated_date'">
          <span
            [ngClass]="{'ic-sort_up' : pagination.sort_dir === 'asc', 'ic-sort_down' : pagination.sort_dir !== 'asc' }"></span>
        </span>
      </button>
      <button id="alphabetical" mat-menu-item (click)="onSort('name')">
        Alphabetical
        <span class="icon ic-dropdown" *ngIf="pagination.sort == 'name'">
          <span
            [ngClass]="{'ic-sort_up' : pagination.sort_dir === 'asc', 'ic-sort_down' : pagination.sort_dir !== 'asc' }"></span>
        </span>
      </button>
    </mat-menu>
    <button id="filter" mat-button color="primary" class="filter-btn" (click)="onOpenFilter()" [ngClass]="{'open': showFilter}">
      <span class="icon ic-filter"></span> Filters
    </button>
  </div>
  <app-landing-filter class="filter-container" sectionKey="landing-filter" [show]="showFilter" (onChange)="onFilter($event)">
  </app-landing-filter>
</div>
<div class="accessinnovation-list-view">
  <div id="loading" fxFlex fxLayout="row" *ngIf="loading" class="loading-wrap">
    <span>Loading...</span>
  </div>
  <div id="noResults" role="alert" aria-live="assertive"  fxFlex fxLayout="row"  *ngIf="!loading && !results.length" class="loading-wrap" >
    <span>No results</span>
  </div>
</div>
<div class="accessinnovation-list-view" *ngIf="!loading">
  <ng-container *ngFor="let result of results;let i=index">
    <app-accessinnovation-list-view id="deliverableComp{{i}}" *ngIf="result.type == 'Deliverable'"
      [deliverable]="result"></app-accessinnovation-list-view>
    <app-story-list id="storyComp{{i}}" *ngIf="result.type == 'Story'" [story]="result"></app-story-list>
  </ng-container>
  <mat-paginator [length]="totalRecords" [pageSize]="pagination.page_size" [pageIndex]="pagination.page_number"
    [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)"></mat-paginator>
</div>