/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, retry, delay } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { UserService } from '../user.service';

@Injectable()
export class UserAPIService implements UserService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  signUp(): Observable<any> {
    this.commonService.log('GET:', '/users/signup', 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/users/signup`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getUsers(searchText: any): Observable<any> {
    searchText = searchText.toLowerCase().trim();
    this.commonService.log('GET:', `/users/search/${searchText}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/users/search/${searchText}`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getLoginUsers(): Observable<any> {
    return of({ result: [] });
  }
}

