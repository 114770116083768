/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from '@app/error/error.component';
import { LoginComponent } from '@app/login/login.component';
import { AccessinnovationDetailViewComponent } from './accessinnovation-detail-view/accessinnovation-detail-view.component';
import { AccessinnovationHomeComponent } from './accessinnovation-home/accessinnovation-home.component';
import { CanDeactivateGuard } from './shared/guards/can-deactivate.guard';
import { StoryViewComponent } from './story-detail-view/story-view.component';

import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { CatalogueEditComponent } from './catalogue-edit/catalogue-edit.component';
import { CatalogueListDetailComponent } from './catalogue-list-detail/catalogue-list-detail.component';
import { CatalogueListViewComponent } from './catalogue-list-view/catalogue-list-view.component';
import { HomeComponent } from './home/home.component';
import { MyCatalogueListViewComponent } from './my-catalogue-list-view/my-catalogue-list-view.component';


const appRoutes: Routes = [
  {
    path: 'auth',
    component: MsalRedirectComponent
  },
  {
    path: '',
    canActivate: [MsalGuard],
    component: HomeComponent
  },
  {
    path: 'home',
    canActivate: [MsalGuard],
    component: AccessinnovationHomeComponent
  },
  {
    path: 'login',
    canActivate: [MsalGuard],
    component: LoginComponent
  },
  {
    path: 'search/view/:deliverable_id',
    canActivate: [MsalGuard],
    component: AccessinnovationDetailViewComponent
  },
  {
    path: 'search/story/view/:story_id',
    canActivate: [MsalGuard],
    component: StoryViewComponent
  },
  {
    path: 'mycatalogues/list',
    canActivate: [MsalGuard],
    component: MyCatalogueListViewComponent
  },
  {
    path: 'catalogues/list',
    canActivate: [MsalGuard],
    component: CatalogueListViewComponent
  },
  {
    path: 'catalogues/list/view/:catalogueId',
    canActivate: [MsalGuard],
    component: CatalogueListDetailComponent
  },
  {
    path: 'catalogues/:catalogue_id',
    canActivate: [MsalGuard],
    canDeactivate: [CanDeactivateGuard],
    component: CatalogueEditComponent
  },
  {
    path: '**',
    component: ErrorComponent
  }
];
export const AppRoutingProviders: any[] = [];
export const Routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes, { useHash: false });
