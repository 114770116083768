/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Services } from '@app/services/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading: any = 0;
  submitted: any = false;
  returnUrl: any = null;
  users: any = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public services: Services
  ) {
    if (this.activatedRoute.snapshot && this.activatedRoute.snapshot.params && this.activatedRoute.snapshot.params.returnUrl) {
      this.returnUrl = this.activatedRoute.snapshot.params.returnUrl;
    }
  }

  ngOnInit(): void {
    this.services.commonService.showHeader.emit(false);
    this.loginForm = this.formBuilder.group({
      username: [null, Validators.required],
    });
    this._getUsers();
  }

  get lForm(): any {
    return this.loginForm.controls;
  }

  _getUsers(): void {
    this.loading++;
    this.services.userService.getLoginUsers().subscribe((response) => {
      this.loading--;
      if (response && response.result) {
        this.users = response.result;
      }
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (!this.loading && this.loginForm.valid) {
      this.loading++;
      this.services.userService.signUp(this.loginForm.getRawValue().username).subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.loading++;
          this.services.authenticationService.setCurrentUserValue(response.result);
          this.services.authenticationService.currentUserSubject.next(response.result);
          this.services.commonService.removeSessionData();
          this.router.navigate(['/home']);
        }
      });
    }
  }
}
