import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Services } from '@app/services/services';

@Component({
  selector: 'app-accessinnovation-list-view',
  outputs: ['onClick'],
  templateUrl: './accessinnovation-list-view.component.html',
  styleUrls: ['./accessinnovation-list-view.component.scss']
})
export class AccessinnovationListViewComponent implements OnInit {
  @Input('deliverable') deliverable: any;
  onClick: EventEmitter<any> = new EventEmitter<any>();
  dataImages: any = {};
  isForList: boolean = true;
  thumbnailSize: any = {
    width: 300,
    height: 200
  }
  constructor(
    public services: Services
  ) { }

  ngOnInit(): void {
  }
  getDataImage(data) {
    if (data && data.image_link) {
      const deliverable = data._id;
      if (this.dataImages[deliverable] && this.dataImages[deliverable] != 'loading') {
        return this.dataImages[deliverable];
      } else if (this.dataImages[deliverable] != 'loading') {
        this.dataImages[deliverable] = 'loading';
        this.services.masterService.getImage(deliverable, 'deliverable', this.isForList, this.thumbnailSize)
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.dataImages[deliverable] = reader.result;
              };
            }
          });
      }
    }
    return this.services.deliverableCommonService.defaultDeliverableImage;
  }
}
