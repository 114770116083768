export enum Role {
    RESEARCHER = 'RESEARCHER',
    GROUP_LEAD = 'GROUP_LEAD',
    WORKSTREAM_LEAD = 'WORKSTREAM_LEAD',
    PROJECT_LEAD = 'PROJECT_LEAD',
    LOCATION_LEAD = 'LOCATION_LEAD',
    INITIATIVE_LEAD = 'INITIATIVE_LEAD',
    SUPER_ADMIN = 'SUPER_ADMIN',
    ADMIN = 'ADMIN',
    GROUP_LOCATION_LEAD = 'GROUP_LOCATION_LEAD',
    PROJECT_TEAM_MEMBER = 'PROJECT_TEAM_MEMBER'
}
