import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-assets-table',
  templateUrl: './assets-table.component.html',
  styleUrls: ['./assets-table.component.scss']
})
export class AssetsTableComponent implements OnInit {
  isShowLink: any = false;
  @Input()
  public tableData;
  @Input()
  public tableOrder?= 'asc';
  @Input()
  public displayedColumns: string[];
  @Input()
  public tableHeaders: string[];
  @Input()
  public searchInput?= '';
  @Input()
  public totalEntries: string;
  @Input()
  public loadVal?= 0;
  @Output() private selectedItem: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void { }
  selectOne(item): void {
    this.selectedItem.emit(item);
  }
}
