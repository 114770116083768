<section fxFlex fxLayout="column" class="single-page">
    <div fxLayout="column" class="single-page-box-wrap">
        <div fxLayout="column" class="single-page-header">
            <h1 id="h_errorRemind" class="center">The page you are trying to access does not exist</h1>
        </div>
        <div fxLayout="column" class="single-page-content">
            <p id="errorText">You found a broken link</p>
        </div>
        <div fxLayout="column" class="single-page-bottom">
            <button id="btn_login" mat-flat-button color="primary" (click)="onLogin()">Home</button>
        </div>
    </div>
</section>