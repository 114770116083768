import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@app/services';
import { Services } from '@app/services/services';
import { Location } from '@angular/common';

@Component({
  selector: 'app-accessinnovation-detail-view',
  templateUrl: './accessinnovation-detail-view.component.html',
  styleUrls: ['./accessinnovation-detail-view.component.scss']
})
export class AccessinnovationDetailViewComponent implements OnInit {
  loading: any = 0;
  routerWatcher: any;
  deliverableId: any;
  deliverable: any = null;
  deliverableImages: any = {};
  allLocations: any = [];
  allGroups: any = [];
  allWorkstreams: any = [];

  constructor(
    public commonService: CommonService,
    private router: Router,
    public location: Location,
    private route: ActivatedRoute,
    public services: Services
  ) { }

  ngOnInit(): void {
    this.commonService.showHeader.emit(true);
    this.routerWatcher = this.route.params.subscribe((params) => {
      this.loading++;
      this.deliverableId = params.deliverable_id;
      this.services.deliverableService.getDeliverable(this.deliverableId)
        .subscribe((response) => {
          this.loading--;
          if (response && response.result) {
            this.handleData(response.result);
            this.deliverable = response.result;
            if (this.deliverable.long_description) {
              let longDescription = this.deliverable.long_description;
              longDescription = longDescription.replace(/[\n\r]/g, '<br>');
              longDescription = longDescription.replace(/( )/g, '&zwnj; ');
              this.deliverable.long_description = longDescription;
            }
          }
        });
    });
  }

  handleData(result): void {
    const primary_group = [];
    const primary_workstream = [];
    const primary_location = [];
    let other_groups = [];
    let other_workstreams = [];
    let other_locations = [];
    if (result.primary_group) {
      primary_group.push(result.primary_group.name)
    }
    if (result.other_groups?.length) {
      other_groups = result.other_groups.map((o) => o.name);
      this.allGroups = primary_group.concat(other_groups);
    } else {
      this.allGroups = primary_group;
    }
    if (result.primary_workstream) {
      primary_workstream.push(result.primary_workstream.name)
    }
    if (result.other_workstreams?.length) {
      other_workstreams = result.other_workstreams.map((o) => o.name);
      this.allWorkstreams = primary_workstream.concat(other_workstreams);
    } else {
      this.allWorkstreams = primary_workstream;
    }
    if (result.primary_location) {
      primary_location.push(result.primary_location.name)
    }
    if (result.other_locations?.length) {
      other_locations = result.other_locations.map((o) => o.name);
      this.allLocations = primary_location.concat(other_locations);
    } else {
      this.allLocations = primary_location;
    }
  }

  getDeliverableImage(deliverable: any): string {
    if (deliverable && deliverable.image_link) {
      const deliverableId = deliverable._id;
      if (this.deliverableImages[deliverableId] && this.deliverableImages[deliverableId] != 'loading') {
        return this.deliverableImages[deliverableId];
      } else if (this.deliverableImages[deliverableId] != 'loading') {
        this.deliverableImages[deliverableId] = 'loading';
        this.services.masterService.getImage(deliverableId)
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.deliverableImages[deliverableId] = reader.result;
              };
            }
          });
      }
    }
    return this.services.deliverableCommonService.defaultDeliverableImage;
  }

  onBack() {
    const previousUrl = this.services.routerExtService.getPreviousUrl();
    if (previousUrl.includes('/search/view/')) {
      this.router.navigate(['/home']);
    } else {
      this.location.back();
    }
  }

}
