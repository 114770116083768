/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Injectable()

export class DeliverableFilterCommonService {
  getMultipleSelectValues(self, field: string, metric = null): any {
    if(self.assetsFilter!=null)
    {
      self.filterForm.get('group').value = self.assetsFilter['group'];
      self.filterForm.get('organization').value = self.assetsFilter['organization'];
      self.filterForm.get('location').value = self.assetsFilter['location'];
      self.filterForm.get('keyword').value = self.assetsFilter['keyword'];
    }
    
    return self.filterForm.get(field).value;
  }

  onAddMultipleSelectValue(self, field: string, event: MatAutocompleteSelectedEvent, metric = null, key = '_id'): void {
    let values = self.filterForm.get(field).value;
    if (!values) {
      values = [];
    }
    let input = document.getElementById(field);
    const index = self.services.commonService.objectIndexOf(values, event.option.value, null);
    if (index < 0) {
      values.push(event.option.value);
    }
    input['value'] = '';
    input.blur();
    self.filterForm.get(field).setValue(values);
  }

  onRemoveMultipleSelectValue(self, field: string, value: any, key = '_id'): void {
    let values = self.filterForm.get(field).value;
    if (!values) {
      values = [];
    }
    const index = self.services.commonService.objectIndexOf(values, value, null);
    if (index >= 0) {
      values.splice(index, 1);
    }
    self.filterForm.get(field).setValue(values);
    if(self.filterSectionKey === 'select-assets-filter'){
      self.assetsFilter[field] = values;
    }
  }

  hasFilter(self) {
    let res = false;
    for (const item in self.filter) {
      if (self.filter[item] && self.filter[item].length) {
        res = true;
      } else if ((item == 'start_qrtr' || item == 'eac_qrtr') && self.filter[item]) {
        res = true;
      }
    }
    return res;
  }
}