export enum Messages {
    HTTP_STATUS_CODE_200 = 200,
    HTTP_STATUS_CODE_401 = 401,
    HTTP_STATUS_CODE_403 = 403,
    HTTP_STATUS_CODE_404 = 404,
    CONTACT_US_MAIL_BODY = '',
    KEYWORD_HINT = 'Search for an existing keyword or type in a new keyword and press enter',
    SAVE_SUCCESS = 'Catalogue saved successfully',
    PUBLISH_SUCCESS = 'Catalogue published successfully',
    UPDATE_SUCCESS = 'Catalogue updated successfully',

    FILE_LIMIT_MESSAGE = 'File exceeds the maximum allowed size of 5MB',
    
    HELP_REF = 'https://in.accenture.com/innovation/accessinnovation/',
    RECOMMEND_MAIL_SUBJECT = 'AccessInnovation Content is being recommended for you to review',
    RECOMMEND_MAIL_BODY_1 = 'Go through this link',
    RECOMMEND_MAIL_BODY_2 = 'for further details',

    CATEGORYADDED_SUCCESS = 'Categroy added successfully',
    CATEGORYADDED_EDITED_SUCCESS = 'Categroy edited successfully',

    SESSION_EXPIRED_DATA_MESSAGE = 'Your session has expired. Please wait, we are logging you back in.',
    UNEXPECTED_ERROR_DATA_MESSAGE = 'Unexpected error occurred. Please refresh your page or relogin our system, and contact acci.support@accenture.com if the error persists.',
    BAD_NETWORK_ERROR_MESSAGE = 'The server encountered a temporary error and could not complete your request. Please refresh your page or relogin our system, and contact acci.support@accenture.com if the error persists.',

    DISCARD_MESSAGE = 'You have not saved your current work. Do you want to discard and proceed?',
    OWNER_HINT = 'Use Accenture Enterprise ID for name selection',
}
