<div fxLayout="column" class="asset-list-container">
  <div fxLayout="row" class="top-line">
    <div fxFlex="35">
      <a [routerLink]="['/search', 'view', deliverable._id]" *ngIf="isShowLink">
        <div id="imagePreview" class="image-preview">
          <div [style.background-image]="'url(' + getDataImage(deliverable) + ')'" class="image-preview-inner">
          </div>
        </div>
      </a>
      <div id="imagePreview" class="image-preview" *ngIf="!isShowLink">
        <div [style.background-image]="'url(' + getDataImage(deliverable) + ')'" class="image-preview-inner">
        </div>
      </div>
    </div>
    <div fxFlex="65" class="right-region">
      <div fxLayout="row">
        <div id="name" class="name">{{deliverable.name}}</div>
      </div>
      <div fxLayout="row">
        <div id="short_description" class="short-description">{{deliverable.short_description}}</div>
      </div>
      <div fxLayout="row" fxLayoutGap="5px">
        <div fxFlex="50">
          <label id="lblOwner" name="lblOwner" class="detail-title">Owner:</label>
          <span id="owner_value" name="owner_value"
            class="detail">{{deliverable.owner_displayname?deliverable.owner_displayname:'N/A'}}</span>
        </div>
        <div fxFlex="50">
          <label id="lblOrganization" name="lblOrganization" class="detail-title">Organization:</label>
          <span id="organization_value" name="organization_value"
            class="detail">{{deliverable.organization_type?deliverable.organization_type:'N/A'}}</span>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="5px">
        <div fxFlex="50">
          <label id="lblGroup" name="lblGroup" class="detail-title">Group:</label>
          <span id="groupvalue" name="groupvalue"
            class="detail">{{deliverable.primary_group?deliverable.primary_group.name:'N/A'}}</span>
        </div>
        <div fxFlex="50">
          <label id="lblLocation" name="lblLocation" class="detail-title">Location:</label>
          <span id="location_value" name="location_value"
            class="detail">{{deliverable.primary_location?deliverable.primary_location.name:'N/A'}}</span>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" class="mask" *ngIf="isChecked"></div>
</div>