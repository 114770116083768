/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@app/services';

import { AppComponent } from '@app/app.component';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {
  userClickLogin = true;
  constructor(
    public commonService: CommonService,
    private router: Router,
    private app: AppComponent) { }

  ngOnInit(): void {
    this.commonService.showHeader.emit(false);
  }

  onLogin() {
    this.app.checkLogin(this.userClickLogin);
  }
}
