/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { CommonService } from './common.service';
import { UserService } from './user.service';
import { UserAPIService } from './api/user-api.service';
import { UserMockService } from './mock/user-mock.service';
import { MasterService } from './master.service';
import { MasterAPIService } from './api/master-api.service';
import { MasterMockService } from './mock/master-mock.service';
import { DeliverableService } from './deliverable.service';
import { DeliverableAPIService } from './api/deliverable-api.service';
import { StoriesAPIService } from './api/stories-api.service';
import { StoriesService } from './stories.service';
import { DeliverableMockService } from './mock/deliverable-mock.service';
export function StoryFactory(http: HttpClient, commonService: CommonService): StoriesService {
  if (environment.demo) {
    return new StoriesAPIService(http, commonService);
  } else {
    return new StoriesAPIService(http, commonService);
  }
}

export function UserFactory(http: HttpClient, commonService: CommonService): UserService {
  if (environment.demo) {
    return new UserMockService(http, commonService);
  } else {
    return new UserAPIService(http, commonService);
  }
}
export function MasterFactory(http: HttpClient, commonService: CommonService): MasterService {
  if (environment.demo) {
    return new MasterMockService(http, commonService);
  } else {
    return new MasterAPIService(http, commonService);
  }
}
export function DeliverableFactory(http: HttpClient, commonService: CommonService): DeliverableService {
  if (environment.demo) {
    return new DeliverableMockService(http, commonService);
  } else {
    return new DeliverableAPIService(http, commonService);
  }

}
