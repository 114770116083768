<div fxLayout="column" class="list-view-container" *ngIf="deliverable">
    <div fxLayout="row" class="top-line">
        <div fxFlex="35" class="img-container">
            <a [routerLink]="['/search', 'view', deliverable._id]" tabindex="0">
                <div id="imagePreview" class="image-preview">
                    <div [style.background-image]="'url(' + getDataImage(deliverable) + ')'" class="image-preview-inner"></div>
                </div>
            </a>
        </div>
        <div fxFlex="65" class="right-region">
            <a [routerLink]="['/search', 'view', deliverable._id]">
                <div id="name" class="name">{{deliverable.name}}</div>
            </a>
            <div id="short_description" class="short-description">{{deliverable.short_description}}</div>
            <div id="long_description" class="long-description">{{deliverable.long_description}}</div>
            <div class="key-box">
                <div id="keywords{{i}}" class="tab" *ngFor="let tab of deliverable.keywords; let i=index">
                        {{tab}}
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-line">
        <div fxLayout="row" fxLayoutGap="10px">
            <div fxLayout="row">
                <label id="lblOwner" name="lblOwner">Owner:</label>
                <span id="owner_value" name="owner_value">{{deliverable.owner_displayname?deliverable.owner_displayname:'N/A'}}</span>
            </div>
            <div fxLayout="row">
                <label id="lblOrganization" name="lblOrganization">Organization:</label>
                <span id="organization_value" name="organization_value">{{deliverable.organization_type?deliverable.organization_type:'N/A'}}</span>
            </div>
            <div fxLayout="row">
                <label id="lblGroup" name="lblGroup">Group:</label>
                <span id="groupvalue" name="groupvalue">{{deliverable.primary_group?deliverable.primary_group.name:'N/A'}}</span>
            </div>
            <div fxLayout="row">
                <label id="lblLocation" name="lblLocation">Location:</label>
                <span id="location_value" name="location_value">{{deliverable.primary_location?deliverable.primary_location.name:'N/A'}}</span>
            </div>
        </div>
    </div>
</div>
