import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@app/services/common.service';
import { Services } from '@app/services/services';
import { Location } from '@angular/common';
import { CatalogueAPIService } from '@app/services/api/catalogue-api.service';

@Component({
  selector: 'app-catalogue-list-detail',
  templateUrl: './catalogue-list-detail.component.html',
  styleUrls: ['./catalogue-list-detail.component.scss']
})
export class CatalogueListDetailComponent implements OnInit {
  routerWatcher: any;
  loading: any = 0;
  catalogue: any = null;
  catalogueImages: any = {};
  canEdite: any = null;
  allAdditionalCurators: any = [];
  allIndustries: any = [];
  allOrganizations: any = [];
  allKeywords: any = [];
  isForList: boolean = true;
  thumbnailSize: any = {
    width: 430,
    height: 265
  }
  constructor(
    public commonService: CommonService,
    public location: Location,
    private route: ActivatedRoute,
    public services: Services,
    private router: Router,
    public catalogueAPIService: CatalogueAPIService,
  ) { }

  ngOnInit(): void {
    this.commonService.showHeader.emit(true);
    this.routerWatcher = this.route.params.subscribe((params) => {
      this.getCatalogue(params.catalogueId);
    });
  }
  getCatalogue(catalogueId) {
    this.loading++;
    this.catalogueAPIService.getMyCatalogue(catalogueId)
      .subscribe((response) => {
        this.loading--;
        if (response && response.catalogue) {
          this.handleData(response.catalogue);
          this.catalogue = response.catalogue;
          this.canEdite = response.rights.canEdite;
        }
      }, (error) => {
        this.loading--;
        this.commonService.handleError(error);
      });
  }

  handleData(result): void {
    if (result.additional_curators?.length) {
      this.allAdditionalCurators = result.additional_curators.map((o) => o.display_name);
    }
    if (result.industries?.length) {
      this.allIndustries = result.industries.map((o) => o.name);
    }
    if (result.organization_type?.length) {
      this.allOrganizations = result.organization_type.map((o) => o.name);
    }
    if (result.keywords?.length) {
      this.allKeywords = result.keywords;
    }
  }

  getCataloguesImage(catalogue: any): string {
    if (catalogue && catalogue.image_link) {
      const catalogueId = catalogue._id;
      if (this.catalogueImages[catalogueId] && this.catalogueImages[catalogueId] != 'loading') {
        return this.catalogueImages[catalogueId];
      } else if (this.catalogueImages[catalogueId] != 'loading') {
        this.catalogueImages[catalogueId] = 'loading';
        this.services.masterService.getImage(catalogueId, 'catalogue', this.isForList, this.thumbnailSize)
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.catalogueImages[catalogueId] = reader.result;
              };
            }
          });
      }
    }
    return null;
  }

  onBack() {
    this.location.back();
  }
  editCatalog(catalogueId) {
    this.router.navigate(['/catalogues', catalogueId]);
  }
}
