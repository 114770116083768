import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Role, User } from '@app/models';
import { CommonService } from '@app/services';
import { Services } from '@app/services/services';
import { CatalogueAPIService } from '../../app/services/api/catalogue-api.service';

@Component({
  selector: 'app-my-catalogue-list-view',
  templateUrl: './my-catalogue-list-view.component.html',
  styleUrls: ['./my-catalogue-list-view.component.scss']
})
export class MyCatalogueListViewComponent implements OnInit {
  results: Array<any> = [];
  totalRecords: any = 0;
  loading: any = 0;
  loadTypeAhead: any = 0;
  loginUser: User = null;
  pageSizeOptions = [9, 18, 27, 36, 45];
  dataImages: any = {};
  pagination: any = {
    page_number: 0,
    page_size: 9,
    sort: 'last_updated_date',
    search: null,
    sort_dir: 'desc'
  };
  isForList: boolean = true;
  thumbnailSize: any = {
    width: 160,
    height: 160
  }

  constructor(
    public commonService: CommonService,
    public services: Services,
    public catalogueAPIService: CatalogueAPIService,
    private cd: ChangeDetectorRef,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.commonService.showHeader.emit(true);
    const pagination = this.commonService.getSessionData('MYCATALOGUE_PAGINATION');
    if (pagination) {
      this.pagination.page_number = pagination.page_number;
      this.pagination.page_size = pagination.page_size;
      this.pagination.search = pagination.search;
      this.pagination.sort = pagination.sort;
      this.pagination.sort_dir = pagination.sort_dir;
    }
    this.services.catalogueEditCommonService.notNeedToRemainPagination.subscribe((notNeedToRemainPagination) => {
      if (notNeedToRemainPagination) {
        this.services.commonService.removeSessionData('MYCATALOGUE_PAGINATION');
      }
    });
    this._getSearchResult();
  }

  get Role(): any { return Role; }
  onPageChange($event): void {
    this.pagination.page_number = $event.pageIndex;
    this.pagination.page_size = $event.pageSize;
    this._getSearchResult();
  }

  _getSearchResult(): void {
    setTimeout(() => {
      this.loadTypeAhead++;
      this.cd.detectChanges();
    });
    this.loading++;
    this.commonService.setSessionData('MYCATALOGUE_PAGINATION', this.pagination);
    this.catalogueAPIService.getFilter(this.pagination, { page: 'myCatalogue' })
      .subscribe((response) => {
        this.loading--;
        if (response && response.catalogues) {
          this.results = response.catalogues;
          this.totalRecords = response.total;
        }
        setTimeout(() => {
          this.loadTypeAhead--;
          this.cd.detectChanges();
        });
      }, (error1) => {
        this.loading--;
        this.commonService.handleError(error1);
      });
  }

  handleIndustriesData(data) {
    let allIndustries = [];
    data.forEach(element => {
      allIndustries.push(element.name);
    });
    return allIndustries.join(' , ');
  }

  getDataImage(data) {
    if (data && data.image_link) {
      const catalogueId = data._id;
      if (this.dataImages[catalogueId] && this.dataImages[catalogueId] != 'loading') {
        return this.dataImages[catalogueId];
      } else if (this.dataImages[catalogueId] != 'loading') {
        this.dataImages[catalogueId] = 'loading';
        this.services.masterService.getImage(catalogueId, 'catalogue', this.isForList, this.thumbnailSize)
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.dataImages[catalogueId] = reader.result;
              };
            }
          });
      }
    }
    return null;
  }

}
