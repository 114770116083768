/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { of, Observable } from 'rxjs';

export class StoriesService {
    getStory(storyId: any): Observable<any> {
        return of(storyId);
    }
}

