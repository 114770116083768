import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Services } from '@app/services/services';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-select-assets-popup',
  templateUrl: './select-assets-popup.component.html',
  styleUrls: ['./select-assets-popup.component.scss']
})
export class SelectAssetsPopupComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  public assetsList;
  public columns: string[] = ['checked', 'asset'];
  selectedAssets: Array<any> = [];
  searchText;
  review: any;
  results: Array<any> = [];
  loading: any = 0;
  optionListAll: Array<any> = [];
  optionScrollPage: any = 1;
  optionScrollSize: any = 5;
  pagination: any = {
    page_number: 0,
    page_size: 10000,
    sort: '',
    search: null,
    sort_dir: ''
  };
  filter;
  loadTypeAhead: any = 0;
  scrollToLocation: any = 500;
  constructor(
    public el: ElementRef,
    public dialogRef: MatDialogRef<SelectAssetsPopupComponent>,
    private cd: ChangeDetectorRef,
    public services: Services
  ) { }
  ngOnInit(): void {
    this.selectedAssets = this.services.commonService.getSessionData('GET_SELECTED_ASSETS');
  }
  scrollToBottom(event): void {
    try {
      if (this.el.nativeElement.querySelector('#assetsTableId').scrollTop + this.scrollToLocation > this.el.nativeElement.querySelector('#assetsTableId').scrollHeight) {
        this.optionScrollPage++;
        this.assetsList = this.optionListAll.slice(0, this.optionScrollPage * this.optionScrollSize);
        this.assetsList = new MatTableDataSource(this.assetsList);
      }
    } catch (err) { }
  }
  /**
   * suggestion
   * @param event 
   */
  getTypeAhead(event) {
    const typeAheadValue = event.target.value;
    this.searchText = typeAheadValue;
    if (typeAheadValue.length >= 2) {
      const reqArguments = {
        searchText: typeAheadValue
      };
      setTimeout(() => {
        this.loadTypeAhead++;
        this.cd.detectChanges();
      });
      this.services.deliverableService.getTypeAheadData(reqArguments).subscribe((typedata) => {
        if (typedata && typedata.suggestions) {
          this.review = typedata.suggestions;
        }
        setTimeout(() => {
          this.loadTypeAhead--;
          this.cd.detectChanges();
        });
      });
    }
    else {
      this.review = null;
    }
    if (typeAheadValue.length == 0) {
      this.ngSelectComponent.handleClearClick();
    }
  }
  /**
   * search field
   * @param $event 
   */
  onTypeAheadSearchSubmit($event = null) {
    if ($event != undefined) {
      this.searchText = $event.target.value;
    }
    this.pagination.search = this.searchText;
    this.pagination.page_number = 0;
    this._getSearchResult();
  }
  _getSearchResult(): void {
    setTimeout(() => {
      this.loadTypeAhead++;
      this.cd.detectChanges();
    });
    this.assetsList = [];
    this.optionListAll = [];
    this.loading++;
    const filter = this.services.deliverableCommonService.getFilterData(this.filter);
    this.services.deliverableService.getFilter(this.pagination, filter)
      .subscribe((response) => {
        this.loading--;
        if (response && response.deliverables) {
          this.results = response.deliverables;
          this.results.forEach((element) => {
            let data = {};
            if (this.selectedAssets?.length > 0) {
              this.selectedAssets.forEach((o) => {
                if (element._id === o._id) {
                  data['checked'] = true;
                }
              });
            } else {
              data['checked'] = false;
            }
            data['asset'] = element;
            this.optionListAll.push(data);
          });
          this.optionScrollPage = 1;
          this.assetsList = this.optionListAll.slice(0, this.optionScrollPage * this.optionScrollSize);
          this.assetsList = new MatTableDataSource(this.assetsList);
        }
        setTimeout(() => {
          this.loadTypeAhead--;
          this.cd.detectChanges();
        });
      }, (error1) => {
        this.loading--;
        this.services.commonService.handleError(error1);
      });
  }
  onCancel(): void {
    this.dialogRef.close();
  }
  onFilter($event) {
    this.filter = $event.data;
    if ($event.type != 'init') {
      this.pagination.page_number = 0;
    }
    this._getSearchResult();
  }
  onSave(): void {
    this.services.commonService.setSessionData('GET_SELECTED_ASSETS', this.selectedAssets);
    this.dialogRef.close(true);
    this.services.deliverableCommonService.isNeedToRefresh.next(true);
  }
  getSelectedOption(selected): void {
    if (this.selectedAssets && this.selectedAssets.length) {
      this.selectedAssets.forEach((item) => {
        if (item._id === selected.asset._id && selected.checked === false) {
          const index = this.services.commonService.objectIndexOf(this.selectedAssets, selected.asset);
          if (index >= 0) {
            this.selectedAssets.splice(index, 1);
          }
        }
      });
      if (selected.checked === true) {
        this.selectedAssets.push(selected.asset);
      }
    } else {
      this.selectedAssets = [];
      this.assetsList.filteredData.forEach((element) => {
        if (element.checked === true) {
          this.selectedAssets.push(element.asset);
        }
      });
    }
  }
}
