<div fxLayout="column" class="select-assets-page">
  <div fxLayout="row">
    <div fxFlex="33" class="filter-field">
      <div class="search-wrap">
        <mat-progress-bar *ngIf="loadTypeAhead" mode="indeterminate" color="accent" aria-label="search"></mat-progress-bar>
        <ng-select id="addSearchKey" class="custom WavSelect WavOption" bindLabel="name" bindValue="name" groupBy="type"
          [(ngModel)]="searchText" placeholder="Search assets… " [markFirst]="false" [editableSearchTerm]="true"
          [inputAttrs]='{"aria-label":"Search"}' [closeOnSelect]="true" (input)="getTypeAhead($event)"
          (keydown.enter)="onTypeAheadSearchSubmit($event)" (change)="onTypeAheadSearchSubmit()" [items]="review">
        </ng-select>
      </div>
      <app-select-assets-filter class="container" sectionKey="select-assets-filter" class="container"  (onChange)="onFilter($event)"></app-select-assets-filter>
    </div>
    <div fxFlex="67" class="form-group">
      <div class="table-field" id="assetsTableId" (scroll)="scrollToBottom($event)">
        <div id="loading" fxLayout="row" *ngIf="loading" class="loading-wrap">
          <span>Loading...</span>
        </div>
        <div id="noResults" fxLayout="row" *ngIf="!loading && !results.length" class="loading-wrap">
          <span>No results</span>
        </div>
        <app-assets-table *ngIf="!loading" [tableData]="assetsList" [displayedColumns]="columns" (selectedItem)="getSelectedOption($event)"></app-assets-table>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div fxLayout="row" fxLayoutGap="60px" class="asset-btn-wrap">
    <button id="cancelBtn" type="button" mat-stroked-button class="cancel-btn" color="primary" (click)="onCancel()">Cancel</button>
    <button id="okBtn" type="button" mat-flat-button class="apply-filter" color="primary" (click)="onSave()">Ok</button>
  </div>
</div>