<div fxLayout="column" class="catalogue-container" *ngIf="results">
	<div fxFlex class="title" >
		<h1 id="page_title"  aria-labelledby="page_title">Access Innovation Catalogues</h1>
		<p id="page_description"  aria-labelledby="page_description"
			[ngStyle]="{'margin-bottom':commonService.hasAnyRole([Role.GROUP_LEAD, Role.WORKSTREAM_LEAD, Role.GROUP_LOCATION_LEAD, Role.LOCATION_LEAD])?'30px':'40px'}">
			Catalogs let you group content and create unique links to all your available assets on a specific subject.
			Catalogs help you prepare workshops or engage other teams around a topic.
		</p>
		<div id="myCatalogueButton" class="my-catalogue-button" [routerLink]="['/mycatalogues/list']"
			[ngStyle]="{'margin-bottom':commonService.hasAnyRole([Role.GROUP_LEAD, Role.WORKSTREAM_LEAD, Role.GROUP_LOCATION_LEAD, Role.LOCATION_LEAD])?'10px':'40px'}">
			My catalogues</div>
		<div fxLayout="column" fxLayoutGap="10px"
			*ngIf="commonService.hasAnyRole([Role.GROUP_LEAD, Role.WORKSTREAM_LEAD, Role.GROUP_LOCATION_LEAD, Role.LOCATION_LEAD])">
			<div id="tips1" class="tips1">You can add new catalogues</div>
			<div id="addNewButton" class="add-new-button" (click)="createCatalogue()" (keydown.enter)="createCatalogue()" tabindex="0" >Create new catalogue</div>
		</div>
		<div id="tips2" class="tips2"
			*ngIf="commonService.hasAnyRole([Role.GROUP_LEAD, Role.WORKSTREAM_LEAD, Role.GROUP_LOCATION_LEAD, Role.LOCATION_LEAD])">
			Or browse through the existing ones</div>
	</div>
	<div fxLayout="row" fxLayoutGap="10px" class="search-body">
		<div class="search-wrap">
			<mat-progress-bar *ngIf="loadTypeAhead" mode="indeterminate" color="accent" aria-label="search"></mat-progress-bar>
      <ng-select id="addSearchKey" class="custom WavSelect WavOption" bindLabel="name" bindValue="name" groupBy="type"
        [(ngModel)]="searchText" placeholder="Search catalogues..." [markFirst]="false" [editableSearchTerm]="true"
        [inputAttrs]='{"aria-label":"Search"}' [closeOnSelect]="true" (input)="getTypeAhead($event)"
        (keydown.enter)="onTypeAheadSearchSubmit($event)" (change)="onTypeAheadSearchSubmit()" [items]="review">
			</ng-select>
		</div>
		<button id="sortMenuIcon" mat-button color="primary" class="sort-btn" [matMenuTriggerFor]="sortMenu">
			<div class="btn-inner">
				<span>
					<span id="s_sort">Sort : </span>
					<span id="s_select" class="sort-value" *ngIf="!pagination.sort">Select </span>
					<span id="s_mostRecent" class="sort-value" *ngIf="pagination.sort == 'last_updated_date'">Most
						Recent</span>
					<span id="s_alphabetical" class="sort-value" *ngIf="pagination.sort == 'name'">Alphabetical</span>
					<span id="s_sort_dir" *ngIf="pagination.sort_dir">({{pagination.sort_dir}})</span>
				</span>
				<span class="icon ic-dropdown"></span>
			</div>
		</button>
		<mat-menu #sortMenu="matMenu" class="live-menu sort-menu">
			<button id="clearSort" mat-menu-item (click)="onSort('')" *ngIf="pagination.sort == 'name' || pagination.sort == 'last_updated_date'">
				Clear Sort
			</button>
			<button id="mostRecent" mat-menu-item (click)="onSort('last_updated_date')">
				Most Recent
				<span class="icon ic-dropdown" *ngIf="pagination.sort == 'last_updated_date'">
					<span
						[ngClass]="{'ic-sort_up' : pagination.sort_dir === 'asc', 'ic-sort_down' : pagination.sort_dir !== 'asc' }"></span>
				</span>
			</button>
			<button id="alphabetical" mat-menu-item (click)="onSort('name')">
				Alphabetical
				<span class="icon ic-dropdown" *ngIf="pagination.sort == 'name'">
					<span
						[ngClass]="{'ic-sort_up' : pagination.sort_dir === 'asc', 'ic-sort_down' : pagination.sort_dir !== 'asc' }"></span>
				</span>
			</button>
		</mat-menu>
		<button id="filter" mat-button color="primary" class="filter-btn" (click)="onOpenFilter()" [ngClass]="{'open': showFilter}">
			<span class="icon ic-filter"></span> Filters
		</button>
	</div>
	<app-tile-filter class="filtercontainer" sectionKey="tile-filter" [show]="showFilter" (onChange)="onFilter($event)"></app-tile-filter>
	<div class="catalogue-list-view">
		<div id="loading" fxFlex fxLayout="row" *ngIf="loading" class="loading-wrap">
			<span>Loading...</span>
		</div>
		<div id="noResults" role="alert" aria-live="assertive" fxFlex fxLayout="row" *ngIf="!loading && !results.length" class="loading-wrap">
			<span>No results</span>
		</div>
	</div>
	<div class="catalogue-list-view" *ngIf="!loading">
		<div class="catalogue-display" fxLayout="row wrap">
			<ng-container *ngFor="let result of results;let i=index">
				<div id="catalogShowCard{{i}}" class="show-card">
					<a id="catalogue{{i}}" [routerLink]="['/catalogues', 'list', 'view', result._id]">
						<div id="imagePreview" class="image-preview"
							[ngClass]="{'image-preview-none': !getDataImage(result) }">
							<div id="imageCard{{i}}"
								[style.background-image]="getDataImage(result) ? 'url(' + getDataImage(result) + ')' : ''"
								class="image-preview-inner"></div>
						</div>
						<div id="cataloguename{{i}}" class="catalogue-name" [matTooltip]="result.name"
							[ngStyle]="{'margin-top':result.industries.length > 1?'4px':result.industries.length == 1?'8px':'17px'}">
							{{result.name}}</div>
						<div id="industriesCard" [matTooltip]="handleIndustriesData(result.industries)">
							<div id="industry{{i}}" class="data-set industry"
								*ngFor="let industry of result.industries | slice:0:1;;let i=index">{{industry.name}}
							</div>
							<div id="ellipsis{{i}}" class="data-set ellipsis"
								*ngIf="result.industries && result.industries.length > 1">...</div>
						</div>
					</a>
				</div>
			</ng-container>
		</div>
		<mat-paginator [length]="totalRecords" [pageSize]="pagination.page_size" [pageIndex]="pagination.page_number"
			[pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)"></mat-paginator>
	</div>
</div>