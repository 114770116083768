<div fxLayout="column" class="my-catalogues" *ngIf="results">
    <div fxFlex class="title">
        <h1 id="page_title"  aria-labelledby="page_title">My Catalogues</h1>
    </div>
    <div class="catalogue-list-view">
        <div id="loading" fxFlex fxLayout="row" *ngIf="loading" class="loading-wrap">
            <span>Loading...</span>
        </div>
        <div id="noResults" role="alert" aria-live="assertive" fxFlex fxLayout="row" *ngIf="!loading && !results.length" class="loading-wrap">
            <span>No results</span>
        </div>
    </div>
    <div class="catalogue-list-view" *ngIf="!loading">
        <div class="catalogue-display" fxLayout="row wrap">
            <ng-container *ngFor="let result of results;let i=index">
                <div id="catalogueCard{{i}}" class="show-card">
                    <div id="workflow_status{{i}}" class="workflow-status">{{result.workflow_status}}</div>
                    <a id="catalogue{{i}}" [routerLink]="['/catalogues', 'list', 'view', result._id]">
                        <div id="imagePreview" class="image-preview" [ngClass]="{'image-preview-none': !getDataImage(result) }">
                            <div id="imageCard{{i}}" [style.background-image]="getDataImage(result) ? 'url(' + getDataImage(result) + ')' : ''" class="image-preview-inner"></div>
                        </div>
                        <div id="cataloguename{{i}}" class="cataloguename" [matTooltip]="result.name" [ngStyle]="{'margin-top':result.industries.length > 1?'4px':result.industries.length == 1?'8px':'17px'}">{{result.name}}</div>
                        <div id="industryFields{{i}}" [matTooltip]="handleIndustriesData(result.industries)">
                            <div id="industry{{i}}" class="data_set industry" *ngFor="let industry of result.industries | slice:0:1;;let i=index">{{industry.name}}</div>
                            <div id="ellipsis{{i}}" class="data_set ellipsis" *ngIf="result.industries && result.industries.length > 1">...</div>
                        </div>
                    </a>
                </div>
            </ng-container>
        </div>
        <mat-paginator [length]="totalRecords" [pageSize]="pagination.page_size" [pageIndex]="pagination.page_number"
            [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)"></mat-paginator>
    </div>
</div>
