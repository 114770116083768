import { Component, OnInit } from '@angular/core';
import { Messages, Role } from '@app/models';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MsalService } from '@azure/msal-angular';

import { Services } from '@app/services/services';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  contactUsLink: string;
  currentYear: any;
  route: string;
  featureFlagForMySuccessStories: any = true;
  constructor(
    private router: Router,
    private location: Location,
    private authService: MsalService,
    public services: Services
  ) {
    this.contactUsLink = `mailto:${environment.CONTACT_US_MAIL_TO}?subject=${environment.CONTACT_US_MAIL_SUBJECT}&body=${Messages.CONTACT_US_MAIL_BODY}`;

  }

  ngOnInit(): void {
    this.currentYear = this.getCurrentDate();
    this.route = this.location.path();
    this.router.events.subscribe(() => {
      if (this.location.path() !== '') {
        this.route = this.location.path();
      } else {
        this.route = '';
      }
    });
  }
  get Role(): any { return Role; }
  onSignOut(): void {
    if (!environment.demo) {
      this.authService.logout();
      this.services.commonService.removeSessionData();
    } else {
      this.services.authenticationService.logout();
      this.services.commonService.removeSessionData();
      this.router.navigate(['/login']);
    }
  }
  getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    return year;
  }
  get Messages(): any { return Messages; }
}
