import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Animations } from '@app/app-animations';
import { CatalogueAPIService } from '@app/services/api/catalogue-api.service';
import { Services } from '@app/services/services';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-tile-filter',
  outputs: ['onChange'],
  templateUrl: './tile-filter.component.html',
  animations: [
    Animations.slideInOut
  ]
})
export class TileFilterComponent implements OnInit {
  allCatalogues: any;
  loading: any = 0;
  loadKeywords: any = 0;
  options: any = {
    organization: [],
    keyword: [],
    industry: []
  };
  keywordChanged: Subject<string> = new Subject<string>();
  filter: any = {
    organization: [],
    keyword: [],
    industry: []
  };
  filterLabels: any = {
    organization: 'Organization',
    keyword: 'Keyword',
    industry: 'Industry'
  }
  @Input('show') show: boolean;
  @Input('sectionKey') filterSectionKey: string;
  onChange: EventEmitter<any> = new EventEmitter<any>();
  filterForm: UntypedFormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public services: Services,
    private cd: ChangeDetectorRef,
    public catalogueAPIService: CatalogueAPIService,
  ) { }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.services.catalogueEditCommonService.getIndustries(this, 'tile_filter');
    this.services.catalogueEditCommonService.getOrganization(this, 'tile_filter');
    this.filterForm = this.formBuilder.group({
      organization: [[]],
      keyword: [[]],
      industry: [[]]
    });
    this.keywordChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.length >= 2) {
          this.options['keyword'] = [];
          this._getKeywords(filterValue);
        }
      });
    if (this.services.commonService.getSessionData(this.filterSectionKey)) {
      this.filter = this.services.commonService.getSessionData(this.filterSectionKey);
    }
    this.onFilter('init');
  }

  onAddMultipleSelectValue(field: string, event: MatAutocompleteSelectedEvent, key = '_id'): void {
    this.services.catalogueFilterCommonService.onAddMultipleSelectValue(this, field, event, null, key);
  }
  _getKeywords(searchText: any): void {
    this.loadKeywords++;
    this.catalogueAPIService.getCataloguesKeywords(searchText, 'Published')
      .subscribe((response) => {
        this.loadKeywords--;
        if (response && response.result) {
          this.options['keyword'] = response.result;
        }
      });
  }
  getMultipleSelectOptions(field: string, key = 'name', onlyTypeHead = false): any[] {
    return this.services.catalogueFilterCommonService.getMultipleSelectOptions(this, field, key, onlyTypeHead);
  }

  onChangeKeyWord(event): void {
    this.options['keyword'] = [];
    this.keywordChanged.next(event.target.value);
  }
  getMultipleSelectValues(field: string): any {
    return this.filterForm.get(field).value;
  }

  onRemoveMultipleSelectValue(field: string, value: any, key = '_id'): void {
    this.services.catalogueFilterCommonService.onRemoveMultipleSelectValue(this, field, value, key);
  }


  onFilter(type) {
    const values = this.filterForm.getRawValue();
    for (const item in values) {
      if (values[item] && values[item].length) {
        values[item].forEach((value) => {
          let index = -1;
          index = this.services.commonService.objectIndexOf(this.filter[item], value, null);
          if (index < 0) {
            this.filter[item].push(value);
          }
        });
      }
    }
    this.filterForm.reset();
    this.services.commonService.setSessionData(this.filterSectionKey, this.filter);
    this.onChange.emit({ type: type, data: this.filter });
  }

  onRemoveFilter(field, option) {
    const index = this.services.commonService.objectIndexOf(this.filter[field], option, null);
    if (index >= 0) {
      this.filter[field].splice(index, 1);
    }
    this.onFilter('removeFilter');
  }

  onClearAllFilter() {
    this.filterForm.reset();
    this.filter = {
      organization: [],
      keyword: [],
      industry: []
    };
    this.onFilter('clearAll');
  }

  hasFilter() {
    return this.services.deliverableFilterCommonService.hasFilter(this);
  }

}
