export const environment = {
  production: true,
  demo: false,
  log: false,
  API_URL: '/api',
  clientId: '51455df2-0aa8-4b28-a469-7a354fd6b4a2',
  authority: 'https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22',
  redirectUri: 'https://ai-test.ciodev.accenture.com/home',
  unprotectedResources: [],
  MAX_RETRY_COUNT: 4,
  CONTACT_US_MAIL_TO: 'acci.support@accenture.com',
  CONTACT_US_MAIL_SUBJECT: 'Accenture ACCI Support',
  RETRY_INTERVAL: 1000
};