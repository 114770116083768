<div fxFlex fxLayout="column" *ngIf="story" class="story-container">
  <div fxLayout="row wrap" fxLayoutGap="30px" class="top-btn-wrap">
    <div id="back" class="back" (click)="onBack()" tabindex="0" (keydown.enter)="onBack()">Back</div>
  </div>
  <div class="black-box">
    <div fxLayout="row" fxLayoutGap="8px" class="breadcrumbs">
      <div>{{story.organization_type?story.organization_type:''}}</div>
      <span *ngIf="story.primary_group"></span>
      <div *ngIf="story.primary_group">{{story.primary_group.name}}</div>
      <span *ngIf="story.primary_location"></span>
      <div *ngIf="story.primary_location">{{story.primary_location.name}}</div>
    </div>
    <h1 id="storyName" class="white-space-pre" aria-labelledby="story_name">{{story.name}}</h1>
    <h3 id="storyShortDesc">{{story.subtitle}}</h3>
    <div fxLayout="row" class="story-type-wrap">
      <div id="storyType" class="story-type">
        {{story.story_type?story.story_type.name:''}} {{story.idf_number? " - " +
        story.idf_number:''}}
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" class="value-group">
      <div fxFlex="75" fxLayout="column" class="text-context">
        <div fxLayout="row">
          <div fxLayout="column" style="flex:1;">
            <div fxLayout="row">
              <div id="storycontext" class="title">Context:</div>
            </div>
            <div id="storyStatus_value" fxFlex class="value">
              <div id="deliverableContext" fxFlex [innerHTML]="story.context | bypassSecurityTrustHtml"></div>
            </div>
          </div>
          <div fxLayout="column" style="flex:1;">
            <div fxLayout="row">
              <div id="storysolution" class="title">Solution:</div>
            </div>
            <div id="storyStatus_value" fxFlex class="value">
              <div id="deliverableSloution" fxFlex [innerHTML]="story.solution | bypassSecurityTrustHtml">
              </div>
            </div>
          </div>
          <div fxLayout="column" style="flex:1;">
            <div fxLayout="row">
              <div id="storyimpact" class="title">Impact:</div>
            </div>
            <div id="storyStatus_value" fxFlex class="value">
              <div id="deliverableImpact" fxFlex [innerHTML]="story.impact | bypassSecurityTrustHtml"></div>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="25" fxLayout="column" class="text-right" *ngIf="options.pictures?.length ==0">
        <div id="imagePreview1" class="image-preview-wrap" *ngIf="story.image_link1">
          <div id="storycontext1" class="picture-title">
            {{story.image_description1}}</div>
          <div class="image-preview">
            <div [style.background-image]="'url(' + getDataStoryImage(story,'story',story.image_link1) + ')'"
              class="image-preview-inner">
            </div>
          </div>
        </div>
        <div id="imagePreview2" class="image-preview-wrap" *ngIf="story.image_link2">
          <div id="storycontext2" class="picture-title">
            {{story.image_description2}}</div>
          <div class="image-preview">
            <div [style.background-image]="'url(' + getDataStoryImage(story,'story',story.image_link2) + ')'"
              class="image-preview-inner">
            </div>
          </div>
        </div>
        <div id="imagePreview3" class="image-preview-wrap" *ngIf="story.image_link3">
          <div id="storycontext2" class="picture-title">
            {{story.image_description3}}</div>
          <div class="image-preview">
            <div [style.background-image]="'url(' + getDataStoryImage(story,'story',story.image_link3) + ')'"
              class="image-preview-inner">
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="25" fxLayout="column" class="text-right" *ngIf="options.pictures?.length>0">
        <div id="imagePreview" *ngFor="let item of options.pictures;let i=index" class="image-preview-wrap">
          <div id="storycontext" class="picture-title">
            {{item.image_description}}</div>
          <div class="image-preview">
            <div [style.background-image]="'url(' + getDataStoryImage(item,'story') + ')'"
              class="image-preview-inner">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="value-bottom">
      <div fxLayout="column">
        <div fxLayout="row">
          <hr class="text-line">
        </div>
        <div fxLayout="row" class="bottom">
          <div fxLayout="row" fxFlex="65" *ngIf="options.icons?.length ==0" id="oldIconsContext">
            <div *ngFor="let item of iconsArr;let i=index">
              <img alt="" id="oldiconsImage{{i}}" [src]="item.data" [width]="item.width" [height]="item.height"
                class="text-icon">
            </div>
          </div>
          <div fxLayout="row" fxFlex="65" *ngIf="options.icons?.length>0" id="logosContext">
            <div *ngFor="let item of iconsArr;let i=index">
              <img alt="" id="deliconsImage{{i}}" [src]="item.data" [width]="item.width" [height]="item.height"
                class="text-icon">
            </div>
          </div>
          <div fxLayout="column" fxFlex="35">
            <div>
              <div id="storycontext" class="title-cotact">Contacts:</div>
            </div>
            <div>
              <div *ngFor="let user of story.contacts_emails;let i=index">
                <div fxFlex fxLayout="row" fxLayoutAlign="end">
                  <a href="mailto:{{user}}?subject=Email Subject&body=Click" class="title"
                    style="font-size:14px;">{{user}}<br /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="text-footer">
        <img src="assets/images/footer.png" alt="" class="story-logo">
        <p class="text-copy">Copyright &#64;
          2023 Accenture. All rights reserved.</p>
      </footer>
    </div>
  </div>
  <div fxLayout="column" class="owner-wrap">
    <div fxLayout="row" fxLayoutGap="30px">
      <div fxFlex="50" fxLayout="column">
      </div>
      <div fxFlex="50" fxLayout="column">
        <div fxLayout="column" fxLayoutGap="11px">
          <div id="title_owner" class="title-name">Owner</div>
          <div fxLayout="row" fxLayoutGap="10px">
            <div id="owner_displayname" class="view-bg data-set">
              {{story.owner?story.owner.display_name:'n/a'}}
            </div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="11px">
          <div id="title_owner" class="title-name">Organization</div>
          <div fxLayout="row" fxLayoutGap="10px">
            <div id="org_displayname" class="view-bg data-set">
              {{story.organization_type?story.organization_type:'n/a'}}
            </div>
          </div>
        </div>
        <div fxLayout="column">
          <div fxLayout="column" fxLayoutGap="11px" *ngIf="allGroups.length">
            <div id="title_owner" class="title-name">Group</div>
            <div fxLayout="row wrap" fxLayoutGap="10px">
              <div id="allGroups{{i}}" class="view-bg data-set" *ngFor="let option of allGroups;let i=index">
                {{option}}</div>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="11px" *ngIf="allWorkstreams.length">
            <div id="title_owner" class="title-name">Workstream</div>
            <div fxLayout="row wrap" fxLayoutGap="10px">
              <div id="allWorkstreams{{i}}" class="view-bg data-set"
                *ngFor="let option of allWorkstreams;let i=index">
                {{option}}</div>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="11px" *ngIf="allLocations.length">
            <div id="title_owner" class="title-name">Location</div>
            <div fxLayout="row wrap" fxLayoutGap="10px">
              <div id="allLocations{{i}}" class="view-bg data-set" *ngFor="let option of allLocations;let i=index">
                {{option}}</div>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="11px" *ngIf="story.keywords?.length">
          <div id="title_owner" class="title-name">Keywords</div>
          <div fxLayout="row wrap" fxLayoutGap="10px">
            <div id="keywords_list{{i}}" class="view-bg data-set" *ngFor="let option of story.keywords;let i=index">
              {{option}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>