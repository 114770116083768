/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Location } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService, CommonService, DeliverableCommonService, MasterService } from '@app/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewChecked {
  displayName: string;
  route: string;
  profileImages: any = {};
  constructor(
    private location: Location,
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthenticationService,
    private deliverableCommonService: DeliverableCommonService,
    private masterService: MasterService,
    public commonService: CommonService,
    private cd: ChangeDetectorRef

  ) {
    this.router.events.subscribe(() => {
      if (this.location.path() !== '') {
        this.route = location.path();
      } else {
        this.route = '';
      }
    });
  }
  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  getCurrentUserProfileImage() {
    const loginUser = this.authService.currentUserValue;
    if (!!loginUser) {
      this.displayName = loginUser.display_name;
    }
    return this._getProfileImage(loginUser ? loginUser.eso_id : null);
  }

  _getProfileImage(userId: any): string {
    if (userId) {
      if (this.profileImages[userId] && this.profileImages[userId] != 'loading') {
        return this.profileImages[userId];
      } else if (this.profileImages[userId] != 'loading') {
        this.profileImages[userId] = 'loading';
        this.masterService.getImage(userId, 'profile')
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.profileImages[userId] = reader.result;
              };
            }
          });
      }
    }
    return this.deliverableCommonService.defaultProfileImage;
  }
  onBackHomePage(){
    this.router.navigate(['/home']);
  }
  navigateToCatalog() {
    this.router.navigate(['/catalogues/list']);
  }
}
