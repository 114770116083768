/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Location } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Services } from '@app/services/services';

@Component({
  selector: 'app-story-view',
  templateUrl: './story-view.component.html',
  styleUrls: ['./story-view.component.scss']
})
export class StoryViewComponent implements OnInit, AfterViewChecked {
  deliverableImages: any = {};
  loading: any = 0;
  routerWatcher: any;
  storyId: any;
  dataImages: any = {};
  story: any = false;
  storyImages: any = {};
  profileImages: any = {};
  allLocations: any = [];
  allGroups: any = [];
  allWorkstreams: any = [];
  loginUser: any = null;
  relatedstorys: Array<any> = [];
  comments: Array<any> = [];
  isLiquidStudio: any = false;
  isLabs: any = false;
  isIncubation: any = false;
  clientInfo: any = '';
  linkName: any = '';
  options: any = {
    pictures: [],
    icons: []
  }
  iconsArr: any = [];
  loadingImgs: any = [];
  tempIconData: any = [];
  historicalIcons: any = [];
  isStopLoadingIcon: any = false;
  isRefreshIcon: any = false;
  publishApprovers: any = '';
  isForList = true;
  relatedDelThumbnailSize: any = {
    width: 300,
    height: 200
  }
  copyimage: any;


  constructor(
    public router: Router,
    public location: Location,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    public services: Services
  ) {
    this.loginUser = this.services.authenticationService.currentUserValue;
  }

  ngAfterViewChecked(): void {
    if (this.options.icons && this.options.icons.length > 0) {
      if (this.tempIconData.length < this.options.icons.length && !this.isStopLoadingIcon) {
        setTimeout(() => {
          this.hasImageLoading();
        }, 500);
      }
      if (this.tempIconData.length > 0) {
        this.iconsArr = this.handleIconsData(this.tempIconData, 'logosContext');
      }
    } else {
      this.prepareIconsData();
    }
    this.cd.detectChanges();
  }
  ngOnInit(): void {
    this.services.commonService.showHeader.emit(true);
    window.addEventListener('resize', () => {
      if (this.historicalIcons && this.historicalIcons.length > 0 && this.tempIconData.length > 0) {
        this.iconsArr = this.handleIconsData(this.historicalIcons, 'oldIconsContext');
      }
      if (this.options.icons && this.options.icons.length > 0 && this.tempIconData.length > 0) {
        this.iconsArr = this.handleIconsData(this.tempIconData, 'logosContext');
      }
    });
    this.routerWatcher = this.route.params.subscribe((params) => {
      this.loading++;
      this.storyId = params.story_id;
      this.services.storiesService.getStory(this.storyId)
        .subscribe((response) => {
          this.loading--;
          if (response && response.story) {
            this.handleData(response.story);
            this.story = response.story;
            this.adjustPicturesOrder();
          }
        });
    });
  }
  getProfileImage(userId: any): string {
    return '';
    // return this.services.storyCommonService.getProfileImage(this, userId);
  }
  /**
   * check if there is any image is loading
   * @returns true: has image loding
   */
  hasImageLoading() {
    if (this.isStopLoadingIcon) {
      return;
    }
    const imgs = [];
    let data = [];
    if (this.options.icons && this.options.icons.length > 0) {
      if (this.loadingImgs.length > 0) {
        data = this.loadingImgs;
      } else {
        data = this.options.icons;
      }
    } else {
      data = this.historicalIcons;
    }
    data.forEach((item) => {
      const icon = new Image();
      icon.src = this.getDataStoryImage(item, 'story');
      const id = item._id;
      if (this.dataImages[id] === 'loading' || icon.width === 0 || icon.height === 0) {
        imgs.push(item);
      } else {
        if (item.order) {
          this.tempIconData.push({ id: id, src: icon.src, width: icon.width, height: icon.height, order: item.order });
        } else {
          this.tempIconData.push({ id: id, src: icon.src, width: icon.width, height: icon.height });
        }
      }
    });
    this.loadingImgs = imgs;
    if (this.tempIconData.length > 0 && this.tempIconData[0].order) {
      this.tempIconData = this.tempIconData.sort(this.compare('order'));
    }
    if (this.loadingImgs.length === 0) {
      this.isStopLoadingIcon = true;
    } else {
      this.isStopLoadingIcon = false;
    }
  }
  handleData(result): void {
    const primary_group = [];
    const primary_workstream = [];
    const primary_location = [];
    let other_groups = [];
    let other_workstreams = [];
    let other_locations = [];
    if (result.primary_group) {
      primary_group.push(result.primary_group.name)
    }
    if (result.other_groups?.length) {
      other_groups = result.other_groups.map((o) => o.name);
      this.allGroups = primary_group.concat(other_groups);
    } else {
      this.allGroups = primary_group;
    }
    if (result.primary_workstream) {
      primary_workstream.push(result.primary_workstream.name)
    }
    if (result.other_workstreams?.length) {
      other_workstreams = result.other_workstreams.map((o) => o.name);
      this.allWorkstreams = primary_workstream.concat(other_workstreams);
    } else {
      this.allWorkstreams = primary_workstream;
    }
    if (result.primary_location) {
      primary_location.push(result.primary_location.name)
    }
    if (result.other_locations?.length) {
      other_locations = result.other_locations.map((o) => o.name);
      this.allLocations = primary_location.concat(other_locations);
    } else {
      this.allLocations = primary_location;
    }
  }

  getDataStoryImage(data: any, type, image_url = null): string {
    if (image_url) {
      data.image_url = image_url;
      data._id = data.image_url.split('/')[2];
    }
    if (data && data.image_url) {
      const dataId = data._id;
      if (this.dataImages[dataId] && this.dataImages[dataId] != 'loading') {
        return this.dataImages[dataId];
      } else if (this.dataImages[dataId] != 'loading') {
        this.dataImages[dataId] = 'loading';
        this.services.masterService.getImage(data.image_url, type, this.isForList, this.relatedDelThumbnailSize)
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.dataImages[dataId] = reader.result;
              };
            }
          });
      }
    }
    return this.services.deliverableCommonService.defaultDeliverableImage;
  }
  onBack() {
    const previousUrl = this.services.routerExtService.getPreviousUrl();
    if (previousUrl.includes('/search/story/view/')) {
      this.router.navigate(['/home']);
    } else {
      this.location.back();
    }
  }

  adjustPicturesOrder() {
    if (this.story.pictures?.length > 0 && this.story.pictures[0].order) {
      this.options.pictures = this.story.pictures.sort(this.compare('order'));
    } else {
      this.options.pictures = this.story.pictures;
    }
    if (this.story.icons?.length > 0 && this.story.icons[0].order) {
      this.options.icons = this.story.icons.sort(this.compare('order'));
    } else {
      this.options.icons = this.story.icons;
    }
  }

  compare(property) {
    return function (a, b) {
      const value1 = a[property];
      const value2 = b[property];
      return value1 - value2;
    };
  }
  /**
     * 
     * @param data image data
     * @param areaId : div id
     * @returns image arrary
     */
  handleIconsData(data, areaId) {
    let heightAssumedMaximum = 42;
    let widthAssumedMinimum = 47;
    let iconTargetWidth = 0;
    let iconTargetHeight = 0;
    const iconHorizontalSpacing = 5;
    let iconTotalWidth = 0;
    let assumedMaximumRatio = 0;
    let widthAssumedMaximum = 0;
    let lessThanNormalWidth = 0;
    let iconAreaMaxWidth = 0;
    const iconAreaMax = document.getElementById(areaId);
    iconAreaMaxWidth = iconAreaMax?.clientWidth;
    const simpleImg = [];
    data.forEach((element) => {
      assumedMaximumRatio = element.height / heightAssumedMaximum;
      widthAssumedMaximum = element.width / assumedMaximumRatio;
      if (widthAssumedMaximum < widthAssumedMinimum) {
        lessThanNormalWidth += widthAssumedMaximum;
      }
      iconTotalWidth += widthAssumedMaximum;
    });
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const totalHorizontalSpacing = data.length * iconHorizontalSpacing;
      const widthRatio = (iconTotalWidth - lessThanNormalWidth) / (iconAreaMaxWidth - totalHorizontalSpacing - lessThanNormalWidth);
      assumedMaximumRatio = item.height / heightAssumedMaximum;
      widthAssumedMaximum = item.width / assumedMaximumRatio;
      iconTargetHeight = heightAssumedMaximum / widthRatio;
      if (iconTargetHeight > heightAssumedMaximum) {
        iconTargetHeight = heightAssumedMaximum;
        iconTargetWidth = widthAssumedMaximum;
      } else {
        iconTargetWidth = widthAssumedMaximum / widthRatio;
      }
      if (widthAssumedMaximum < widthAssumedMinimum) {
        iconTargetHeight = heightAssumedMaximum;
        iconTargetWidth = widthAssumedMaximum;
      }
      simpleImg.push({ data: item.src, height: iconTargetHeight, width: iconTargetWidth });
    }
    return simpleImg;
  }
  prepareIconsData() {
    if (this.historicalIcons.length > 0) {
      if (this.tempIconData.length < this.historicalIcons.length && !this.isStopLoadingIcon) {
        setTimeout(() => {
          this.hasImageLoading();
        }, 500);
      }
      if (this.tempIconData.length > 0) {
        this.iconsArr = this.handleIconsData(this.tempIconData, 'oldIconsContext');
      }
    } else {
      if (this.story.icon_link1) {
        this.prepareOldIconArr(this.story.icon_link1);
      }
      if (this.story.icon_link2) {
        this.prepareOldIconArr(this.story.icon_link2);
      }
      if (this.story.icon_link3) {
        this.prepareOldIconArr(this.story.icon_link3);
      }
    }
  }
  /**
   * 
   * @param imgLink image link
   */
  private prepareOldIconArr(imgLink) {
    const id = imgLink.split('/')[2];
    this.historicalIcons.push({
      id: id,
      image_url: imgLink
    });
  }
}
