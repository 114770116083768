<div fxLayout="column" class="catalogue-list-detail" *ngIf="catalogue">
  <div fxLayout="row" fxLayoutGap="10px" class="top-btn-wrap button-line">
    <div id="back" class="back" (click)="onBack()" (keydown.enter)="onBack()" tabindex="0">Back</div>
    <div class="edit-btn" (click)="editCatalog(catalogue._id)" (keydown.enter)="editCatalog(catalogue._id)" tabindex="0" *ngIf="canEdite">
      <span id="editCatalog" class="icon"><i class="fa fa-edit"></i>Edit this catalog</span>
    </div>
  </div>
  <div fxLayout="column" fxLayoutGap="10px" class="catalogue-detail">
    <div fxLayout="row" fxLayoutGap="10px">
      <div id="catalogueName" class="name" aria-labelledby="catalogueName" >{{catalogue.name}}</div>
    </div>
    <div fxLayout="row" fxLayoutGap="25px">
      <div fxLayout="column" fxFlex="50" fxLayoutGap="10px">
        <div fxLayout="column" fxLayoutGap="10px">
          <div id="short_description">{{catalogue.short_description}}</div>
        </div>
        <div fxLayout="column" fxLayoutGap="10px">
          <div id="long_description">{{catalogue.long_description}}</div>
        </div>
      </div>
      <div fxLayout="column" fxFlex="50" fxLayoutGap="10px" class="right-column">
        <div id="imagePreview" class="image-preview" [ngClass]="{'image-preview-none': !getCataloguesImage(catalogue) }">
          <div [style.background-image]="getCataloguesImage(catalogue) ? 'url(' + getCataloguesImage(catalogue) + ')' : ''" class="image-preview-inner"></div>
        </div>
          
          <div fxLayout="row" fxLayoutGap="10px" *ngIf="allOrganizations.length">
            <div id="title_organization" fxFlex="30" class="title-name">Organization:</div>
            <div fxLayout="row wrap" fxFlex="70" fxLayoutGap="10px">
                <div id="allOrganizations{{i}}" class="view-bg data-set" *ngFor="let option of allOrganizations;let i=index" >{{option}}</div>
            </div>
          </div>
             <div fxLayout="row" fxLayoutGap="10px">
            <div id="title_primarycurator" fxFlex="30" class="title-name curators">Primary curator:</div>
            <div fxLayout="row wrap" fxFlex="70" fxLayoutGap="10px">
                <div id="primary_curator" class="view-bg data-set">{{catalogue.primary_curator.display_name}}</div>
            </div>
          </div> 
          <div fxLayout="row" fxLayoutGap="10px" *ngIf="allAdditionalCurators.length">
            <div id="title_additionalcurators" fxFlex="30" class="title-name curators">Additional curators:</div>
            <div fxLayout="row wrap" fxFlex="70" fxLayoutGap="10px">
                <div id="allAdditionalCurators{{i}}" class="view-bg data-set" *ngFor="let option of allAdditionalCurators;let i=index" >{{option}}</div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" *ngIf="allIndustries.length">
            <div id="title_industry" fxFlex="30" class="title-name">Industry:</div>
            <div fxLayout="row wrap" fxFlex="70" fxLayoutGap="10px">
                <div id="allIndustries{{i}}" class="view-bg data-set" *ngFor="let option of allIndustries;let i=index" >{{option}}</div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" *ngIf="allKeywords.length">
            <div id="title_keywords" fxFlex="30" class="title-name">Keywords:</div>
            <div fxLayout="row wrap" fxFlex="70" fxLayoutGap="10px">
                <div id="allKeywords{{i}}" class="view-bg data-set" *ngFor="let option of allKeywords;let i=index" >{{option}}</div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" fxLayoutGap="10px" class="accessinnovation-list-view">
    <div class="assets-list">
      <ng-container *ngFor="let result of catalogue.relevant_assets;let i=index">
        <app-accessinnovation-list-view id="deliverableComp{{i}}" [deliverable]="result">
        </app-accessinnovation-list-view>
      </ng-container>
    </div>
  </div>
</div>