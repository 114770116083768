<header fxLayout="row">
  <div id="heading-layoutbox1" fxLayout="row wrap" fxLayoutAlign="space-between center" class="navHead">
    <div id="heading-layoutbox2" class="left-wrap">
      <img _ngcontent-jlu-c82="" class="heading-img" alt="logo" src="../../assets/images/ACC-log.svg" />
      <span class="heading-title" tabindex="0"  (click)="onBackHomePage()" (keydown.enter)="onBackHomePage()">Access Innovation</span>
    </div>
    <div fxFlex="0 0 0 0" fxLayout="row" fxLayoutGap="20px" class="right-wrap">
      <span id="catalogBtn" class="catalogue_title" [routerLink]="['/catalogues/list']" tabindex="0" (keydown.enter)="navigateToCatalog()">Catalogues</span>
      <div class="user-avatar">
        <span class="user-font">{{displayName}}</span>
        <img id="peoplePicture" [src]="getCurrentUserProfileImage()" class="user-icon" alt="peoplePicture" />
      </div>
    </div>
  </div>
</header>
