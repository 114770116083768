/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { of, Observable } from 'rxjs';

export class MasterService {
  getDeliverableTypes(params = null): Observable<any> {
    return of(params);
  }
  getProjects(params = null): Observable<any> {
    return of(params);
  }

  getInitiatives(params = null): Observable<any> {
    return of(params);
  }

  getQuarters(): Observable<any> {
    return of(null);
  }

  getGroups(params = null): Observable<any> {
    return of(params);
  }

  getFunctions(): Observable<any> {
    return of(null);
  }

  getIndustries(): Observable<any> {
    return of(null);
  }

  getImage(id: string, type = 'deliverable', isForList = false, thumbnailSize = {}): Observable<any> {
    return of({ id, type, isForList, thumbnailSize });
  }

  getProfileImage(id: string): Observable<any> {
    return of({ id });
  }
  getLocations(params = null): Observable<any> {
    return of(params);
  }

  saveOrder(params): Observable<any> {
    return of(params);
  }
  getWorkstreams(): Observable<any> {
    return of(null);
  }
}